.allNotification_container {
  display: grid;
  justify-items: center;
  height: 85vh;
  overflow: hidden;
  background: rgb(224 240 226 / 6%);

  .subNotification_Container {
    width: 55%;
    max-width: 1200px;
    margin: 40px auto;
    background: white;
    border-radius: 20px;
    height: max-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .listBox {
      border-radius: 10px 10px 0 0;
      outline: 1px solid #5b5b5b5b;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ul {
        display: flex;
        align-items: center;
        gap: 10px;

        li {
          list-style: none;
          border-radius: 15px;
          font-weight: 600;
          font-size: 20px;
        }
      }

      span {
        margin-right: 25px;
        background: rgb(28, 140, 56);
        color: white;
        border-radius: 100%;
        padding: 8px;
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .notification_container {
      outline: 1px solid rgba(91, 91, 91, 0.3568627451);
      padding: 15px 10px;
      border-radius: 0 0 20px 20px;
      height: 66vh;
      overflow-y: scroll;
      ::-webkit-scrollbar{
        width: 0px;
      }

      .userDetail_coantainer {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 10px;

        &.read {
          &:hover {
            cursor: pointer;
            background-color: #63636322;
          }
         
        }
        &.notread {
           background: #07b2730c;
          &:hover {
            cursor: pointer;
            background: #07b27323;
           
            &:before{
                border-radius: 0;
                 transition: all 0.3s ease;
            }
          }
           &:before{
             content: "";
          width: 10px;
          height: 30px;
          background-color: var(--primary-green);
          border-radius: 50%;
          display: inline-block;
          position: relative;
          right: 25px;
          margin-right: -10px;
          }
        }

       

        .useDetail {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            .userDetail_Box p {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

        .userDetail_Icon{
            display: flex;
           flex-direction: column;
           align-items: flex-end;
           margin-left: auto;

           p{
            margin: 0;
           }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .subNotification_Container {
      width: 80%;

      .notification_container .userDetail_coantainer {
        .userDetail_Box {
          p {
            font-size: 15px;
          }
        }

        .listBox {
          ul li {
            padding: 5px;
          }

          span {
            font-size: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .subNotification_Container {
      width: 80%;

      .notification_container .userDetail_coantainer {
        padding: 10px;

        .listBox {
          ul li {
            padding: 5px;
          }

          span {
            font-size: 10px;
          }
        }

        .userDetail_Box {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
