.chatbox_wrapper {
  padding: 4rem 2rem;
  background: rgb(224 240 226 / 6%);
}

.chatbox_Container {
  ::-webkit-scrollbar {
    width: 0px;
  }

  display: grid;
  grid-template-columns: 33% 67%;
  margin: auto;
  height: 80vh;
  max-width: 1200px;
  outline: 1px solid #5b5b5b5b;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .chatbox_userbox {
    border-right: 1px solid rgb(142, 142, 142);
    padding: 2rem;
    overflow-y: scroll;
    line-height: 2;

    // margin-right: 5%;
    .chatbox_search_button {
      position: absolute;
      top: 50%;
      right: 3%;
      transform: translate(0, -50%);
      font-size: 21px;
    }

    .chatbox_user_search {
      width: 100%;
      outline: none;
      padding: 10px;
      border-radius: 20px;
      margin-bottom: 20px;
    }

    .chatbox_Detailbox {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 5px;
      margin: 8px 0;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .chatbox_userDetail {
        width: 100%;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4,
        p {
          padding: 0;
          margin: 0;
        }

        h4 {
          font-size: 16px;
          font-weight: 600;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: #939292;
        }
      }

      &:hover {
        background-color: rgb(7 178 116 / 8%);
        cursor: pointer;

        .chatbox_userDetail p {
          color: #cac8c8;
        }
      }
    }

    .active {
      background-color: rgb(7 178 116) !important;
      color: #fff;

      &:hover {
        background-color: rgb(7 178 116) !important;
        color: #fff;
      }

      .chatbox_userDetail p {
        color: #efeeee;
      }
    }
  }

  .chatbox_chatbox {
    height: 100%;
    overflow-y: hidden;
    line-height: 2;
    /* background: #f7f7f7; */
    position: relative;
    background: #f7f7f74d;

    .file-upload-container {
      padding: 0px 6% 8px;
    }

    .input-container {
      padding: 10px 5%;
      padding-top: 20px;
      border-top: 1px solid #ccc;
    }

    .chatbox_Empty_Image {
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    .chatbox_userDetail {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 10px;
      background-color: #fff;
      border-radius: 0 20px 0 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .chatbox_box {
      padding: 10px;
      display: flex;
      flex-direction: column;
      height: 50vh;
      overflow-y: scroll;
      padding: 0 10px;

      span {
        font-weight: 500;
        display: flex;
        background: rgba(151, 173, 198, 0.0588235294);
        width: max-content;
        padding: 0 8px;
        outline: 1px solid rgba(91, 91, 91, 0.3568627451);
        border-radius: 5px;
        margin: 5px auto;
        color: #5b5b5b;
        font-size: 12px;
        justify-content: center;
      }

      .chatbox_UserDetail {
        display: inline-grid;
        justify-content: center;
        padding-top: 25px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        h4 {
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          margin: 0;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: #7c7c7c;
          padding: 0;
          margin: 0;
        }
      }

      .chatbox_message_left {
        display: flex;
        align-items: center;
        gap: 10px;
        background: transparent;

       
        p {
          background-color: #e2dfdf;
          padding: 5px 10px;
          border-radius: 15px;
        }
        a{
          background-color: white;
        }
      }

      .chatbox_message_right {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 10px;
        background: transparent;

        a{
          background-color: white;
        }
        p {
          background-color: #b9f4df;
          padding: 5px 10px;
          border-radius: 15px;
        }
      }
    }

    .chatbox_messageInputbox {
      &>input[type="text"] {
        padding: 12px !important;
      }
    }

    .chant_box_bottom {
      .chat_file_icon {
        position: absolute;
        top: 35%;
        left: 2%;
        transform: translate(0, -33%);
        display: flex;
        justify-content: space-between;
        padding: 0;
        font-size: 20px;
      }

      .chat_message_button {
        position: absolute;
        top: 36%;
        right: 28px;
        transform: translate(0, -33%);
        display: flex;
        justify-content: space-between;
        padding: 7px 10px;
      }
    }
  }
}