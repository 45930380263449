@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");

:root {
  --primary-green: #07b274;
  --sec-green: #07b273;
}

body {
  margin: 0;
  line-height: normal;
  font-family: poppins;
  scroll-behavior: smooth;
  transition: scroll-behaviour 0.3s ease-in-out;
}
.menu-bar {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  display: none;
}
.myLink{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.myarrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}
.myLi{
  width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}
.myright {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 10px 0;
}

/* Remove border and background for specific links */
.no-border {
  border: none;
  background: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
}

.nav-toggle {
  cursor: pointer;
  font-size: 20px;
}

.menu-links {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.menu-links.show {
  max-height: 500px;
  /* Adjust this to suit the menu height */
}

.mil_button {
  background-color: var(--sec-green);
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  width: 130%;
margin-top: 8rem;
  font-size: 15px;

 

 

}

.login-signup button {
  margin: 5px;
  padding: 8px 12px;
  background-color:var(--sec-green);;
  color: white;
  border: none;
  cursor: pointer;
 

}

html {
  scroll-behavior: smooth;
}

.errorborder {
  border: 1px solid red !important;
}

.handleBodyfromScroll {
  overflow: hidden;
}

.green-color {
  color: var(--primary-green);
}

.ant-select-selector {
  padding: 6px 10px !important;
  border-radius: 3px !important;
}

.btn-outline {
  display: flex;
  outline: none;
  border: 1px solid var(--primary-green);
  color: var(--primary-green);
  background-color: white;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  /* width: 200px; */
  /* height: 60px; */
  font-size: 16px;

  &.buy-bid {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 13px;
    margin-top: 15px;
    margin-inline: auto;
    transition: all 0.3s ease-in-out;

    &:hover {
      letter-spacing: 1px;
    }
  }
}

.btn-outline:hover {
  background: rgba(7, 178, 116, 1);
  color: white;
  gap: 5px;
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  color: var(--primary-green);
}

/* indexv1.css*/

.jobs-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 50px;
}

.jobs-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;

  &>img {
    padding-block: 10px;
    height: auto;
    width: 45%;
    min-width: 205px;
  }

  &>h3 {
    font-size: 22px;
    font-weight: 600;
    color: #5b5b5b;
    margin: 0;
  }

  &>p {
    color: #5b5b5b;
    margin-top: 0;
    font-weight: 500;
  }

  &>.buton {
    margin: 20px;
    font-weight: 600;
    font-size: 14px;
    padding: 15px 12%;
  }
}

.jobs-card-open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-v1-item {
  background-color: #fcfaf4;
  height: 700px;
}

.designer {
  position: absolute;
  top: 6px;
  left: 32px;
  font-weight: 500;
}

.its-easy-to {
  &>h2 {
    font-size: 35px;
    margin-top: 60px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
  }

  &>p {
    margin-top: 15px;
    padding-inline: 6rem;
    text-align: center;
    font-weight: 500;
    color: #606563;
    font-size: 16px;
  }
}

.index-v1 {
  position: relative;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  text-align: left;
  font-size: 30px;
  color: #0d0d0d;
  font-family: Poppins;
}

/* blogger.css */
.blogs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 60px; */
  max-width: 1300px;
  margin-block: 2rem;
  margin-inline: auto;
}

.blogs1 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  color: rgba(0, 166, 80, 0.47);
}

.our-latest-news {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
}

.all-blogs {
  display: flex;
}

.edusubtitle{
  font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    color: rgb(1 1 1 / 70%);
}

/* Tshirt design */
.card1 {
  /* height: 624px; */
  background-color: white;
  width: 400px;
  display: flex;
  border: 1px solid #80808029;
  margin: 0 10px;
  flex-direction: column;
}

.card1>img {
  width: 100%;
  height: 271px;
}

.tags-and-time {
  display: flex;
  justify-content: space-between;
}

.card-content {
  margin: 15px 15px;
}

.design {
  background-color: rgba(96, 208, 147, 0.429);
  color: rgba(0, 100, 0, 0.717);
  padding: 0px 5px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
}

.time {
  font-size: 14px;
  padding: 2px 4px;
  line-height: normal;
  height: 18px;
  color: rgba(145, 142, 142, 0.909);
}

.blog-head {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  margin: 20px 0;
}

.blog-content {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #617798;
}

.right-arrow {
  margin: auto 20px 0px 20px;
  align-self: flex-end;
  justify-self: flex-end;
}

.view-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 1300px) {
  .our-latest-news {
    font-size: 36px;
  }

  .all-blogs {
    flex-wrap: wrap;
    justify-content: center;
  }

  .card1 {
    margin: 10px;
    height: auto;
  }

  .blogs {
    padding: 40px 20px;
  }
}

@media (max-width: 1070px) {
  .blogs1 {
    font-size: 16px;
  }

  .card1 {
    width: 370px;
    margin: 10px;
    height: auto;
  }

  .blog-head {
    font-size: 20px;
  }

  .blog-content {
    font-size: 16px;
  }

  .blogs {
    padding: 30px 10px;
  }
}
.nav-links{
  display: none;
}

@media (max-width: 768px) 
.menu-bar{
  display: block;
}

  .blogs1 {
    font-size: 16px;
  }

  .our-latest-news {
    font-size: 28px;
  }
    .nav-toggle {
      cursor: pointer;
      font-size: 20px;

  .card1 {
    margin: 10px;
    height: auto;
  }

  .blog-head {
    font-size: 18px;
  }

  .blog-content {
    font-size: 14px;
  }

  .card1>img {
    height: auto;
  }

  .btn-outline {
    padding: 8px 10px;
    height: auto;
    font-size: 14px;
    width: 150px;
  }

  .view-btn>img {
    width: 20px;
  }

  .its-easy-to {
    &>h2 {
      font-size: 20px;
    }

    &>p {
      padding-inline: 5px;
      font-size: 14px;
    }
  }
}
.nav-links{
  display: none;
}

@media (max-width: 430px) {
  .blogs1 {
    font-size: 14px;
  }

  .blogs {
    padding: 20px 0px;
  }

  .our-latest-news {
    font-size: 24px;
    line-height: normal;
  }

  .all-blogs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card1 {
    width: 90%;
    margin: 10px;
    height: auto;
  }

  .blog-head {
    font-size: 18px;
  }

  .blog-content {
    font-size: 14px;
  }

  .btn-outline {
    padding: 8px 10px;
    height: auto;
    font-size: 12px;
    width: 150px;
  }

  .view-btn>img {
    width: 20px;
  }
}

/*category.css*/
.footer {
  background-color: black;
  color: white;
  width: 100%;

  &>hr {
    margin-bottom: 0px;
    opacity: 0.3;
    width: 87%;
    max-width: 1360px;
  }
}

.all-footer {
  display: flex;
  margin: auto;
  width: 87%;
  max-width: 1360px;
  align-items: baseline;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  gap: 15%;
}

.links-1 {
  display: flex;
  flex-direction: column;
}

.links-1 ul li,
.links-2 ul li,
.links-3 ul li {
  cursor: pointer;
}

.links-2 {
  display: flex;
  flex-direction: column;
}

.links-3 {
  display: flex;
  flex-direction: column;
}

.footer-links ul {
  list-style-type: none;
  font-size: 12px;
  line-height: 3;
  color: white;
  opacity: 0.9;
  padding: 0;
}

.links-1>li,
.links-2>li,
.links-3>li {
  list-style: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
}

.email {
  margin-top: 40px;
}

.email-heading>h5 {
  font-weight: 500;
  font-size: 16px;
}

.email-heading>p {
  font-size: 12px;
  margin-block: 10px !important;
  /* font-weight: 500; */
  width: 72%;
  font-size: 14px;
  line-height: 19px;
}

.email-input {
  width: 600px;
  height: 60px;
  display: flex;
}

.email-input>input {
  outline: none;
  border: none;
  background-color: white;
  color: gray;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
  line-height: 30px;
  width: 90%;
  height: 80%;
  padding-left: 16px;
  box-sizing: border-box;
}

.email-input>button {
  outline: none;
  border: none;
  width: 33%;
  height: 80%;
  background-color: var(--primary-green);
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  line-height: 30px;
  box-sizing: border-box;
}

.copywrite-social-media {
  display: flex;
  justify-content: space-between;
  padding-block: 20px;
  width: 87%;
  max-width: 1360px;
  margin: auto;
  align-items: center;

  &>img {
    width: 112px;
  }
}

.copy {
  font-size: 14px;
  opacity: 50%;
  margin-left: 80px;
}

.social-media>img {
  width: 200px;
  margin-bottom: 20px;
}

.follow>h5 {
  margin-bottom: 10px;
  font-size: 16px;
}

.follow-icon>svg {
  height: 2.5em;
  width: 2.5em;
  margin-right: 10px;
  margin-bottom: 2em;
}

@media (max-width: 1300px) {
  .email {
    margin: 0 54px;
  }

  .email-heading>h5 {
    font-weight: 500;
    font-size: 32px;
  }

  .email-heading>p {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 30px;
  }
  .nav-links{
    display: none;
    font-size: 10px;
  }

  .email-input {
    width: 500px;
    height: 60px;
  }

  .email-input>input {
    font-size: 16px;
    line-height: 0%;
  }

  .email-input>button {
    font-size: 16px;
    line-height: 0;
  }
}

@media (max-width: 1070px) {
  .all-footer {
    flex-flow: column-reverse;
    padding: 100px 40px;
  }
}
.nav-links{
  display: none;
}

@media (max-width: 768px) {
  .all-footer {
    flex-flow: column-reverse;
    padding: 0 20px;
  }

  .email-heading>h1 {
    margin-bottom: 50x;
    line-height: normal;
  }
}

@media (max-width: 500px) {
  .footer-links {
    margin-left: 0;
  }

  .all-footer {
    margin: 0;
  }

  .email {
    margin: 0;
  }

  .email-head {
    margin: 0;
  }

  .email-input {
    width: 90%;
    height: 40px;
  }

  .email-input>input {
    font-size: 12px;
    width: 65%;
  }

  .email-input>button {
    font-size: 12px;
    padding: 0;
  }

  .copywrite-social-media {
    justify-content: center;
    align-items: center;
    flex-flow: column-reverse;
  }

  .copy {
    margin: 0;
  }

  .social-media {
    margin: 0;
  }

  .links-1>li,
  .links-2>li,
  .links-3>li {
    padding-left: 4px;
  }

  .footer-links ul {
    padding: 0 4px;
  }

  .email-heading>h5 {
    font-size: 18px;
  }

  .email-heading>p {
    font-size: 12px;
  }
}
.nav-links{
  display: none;
}
/* client reviews css */
.hear-from-our {
  margin-top: 50px;
  line-height: 70px;
  font-weight: 600;
  text-align: center;
}

.hear-from-our-clients-parent {
  font-size: 56px;
  color: #0d0d0d;
  font-family: Poppins;
}

.rev-con {
  display: flex;
  justify-content: space-around;
  margin: 20px 80px;
}

/* groupContainer .css */
.review_container_box {
  /* flex-wrap: wrap; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 30% 30% 30%;
  gap: 15px;
  justify-content: center;
  flex: 1;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .review_container_box {
    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 500px) {
  .review_container_box {
    grid-template-columns: 100%;
  }
}

.rectangle-parent-7 {
  border: 1px solid rgba(128, 128, 128, 0.441);
  display: flex;
  padding: 25px;
  justify-content: left;
  flex-direction: column;
  box-sizing: border-box;
  /* margin-inline: 8%; */
  /* max-width: 70vw; */

  & .aname {
    margin-top: 0;
  }
}

.comma {
  width: 92px;
  color: green;
}

.reviews {
  font-size: 16px;
  color: gray;
}

.aname {
  font-size: 24px;
  font-weight: 800;
  margin: 0;
}

.star {
  font-size: 10px;
  margin-top: -10px;
}

@media (max-width: 1300px) {
  .hear-from-our-clients-parent {
    font-size: 40px;
  }
  .d logo{
    width: 215px !
    ; 
  }

  .rev-con {
    justify-content: space-around;
    align-items: center;
    margin: 20px 40px;
  }

  .comma {
    width: 60px;
  }

  .aname {
    font-size: 24px;
  }
}



@media (max-width: 1070px) {
  .hear-from-our-clients-parent {
    font-size: 36px;
  }

  .rev-con {
    justify-content: center;
    align-items: center;
    margin: 20px 30px;
  }

  .aname {
    font-size: 20px;
  }

  .reviews {
    font-size: 16px;
    color: gray;
  }
}
.login-signup {
  margin-left: 8px;
  display: flex;
  flex-direction:row !important;
  gap: 1rem;
}

@media (max-width: 768px) {
  .rev-con {
    justify-content: center;
    align-items: center;
    margin: 20px 40px;
    flex-direction: column;
  }
    .login-signup {
      margin-left: 8px;
      display: flex;
  flex-direction: column!important;
      gap: 0.1rem !important;
  
    }

  .rectangle-parent-7 {
    /* width: 90%; */
    height: auto;
    margin-bottom: 20px;
  }

  .reviews {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .hear-from-our-clients-parent {
    font-size: 24px;
  }
.login-signup {
  margin-left: 8px;
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
}
  .rev-con {
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
    flex-direction: column;
  }
    .nav-toggle {
      cursor: pointer;
      font-size: 20px;
    }

  .rectangle-parent-7 {
    /* width: 90%; */
    height: auto;
    margin-bottom: 20px;
  }

  .comma {
    width: 50px;
  }

  .aname {
    font-size: 20px;
  }

  .star {
    margin-top: 0;
  }

  .reviews {
    font-size: 12px;
  }
}

/* header.css */
.group-nav {
  height: 80px;
  box-shadow: 0 4px 50px 0px rgba(44, 50, 65, 0.03);
  background: white;
}

.nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.logo {
  display: flex;
}

.links {
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-signup {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  gap:1rem;

}


.login-signup>button {
  background: transparent;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: black;
  line-height: 24px;
  cursor: pointer;

  &:hover {
    color: rgba(7, 178, 115, 0.548);
  }
}

.btn {
  background: rgba(7, 178, 116, 1);
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-style: poppins;
  cursor: pointer;
}

.btn_form {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  font-size: initial;
  font-weight: 600;
  margin: 20px;
  width: 30%;
  padding: 10px 30px;
}

.search {
  background: rgba(7, 178, 116, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(7, 178, 116, 1);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn :hover {
  background: rgba(7, 178, 115, 0.548);
}

.post-job>button {
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

.nav-links {
  display: flex;
  list-style: none;
  color: black;
  opacity: 1;
  justify-content: space-between;

}

.nav-links>li {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 37px;
  margin: 0 10px;
  

}

.nav-links>li>a {
  text-decoration: none;
  color: initial;
  
}

.nav-links>li>a:active {
  color: var(--primary-green);
}

.menubar {
  display: none;
  font-size: 58px;
}

.menu-bar {
  display: none;
}

.menu-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userName {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-weight: 500;
}

.header-img {
  height: 50px;
  width: 50px;
  cursor: pointer;
  border-radius: 50%;
}

.search-noti {
  display: flex;
}

.search-noti>img {
  padding: 0 10px;
}

@media (max-width: 1300px) {
  .nav-links>li {
    cursor: pointer;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0 10px;
  }
    .dlogo {
      width: 200px !important;
  
    }
     .buttons {
       display: none;
     }

  .userName {
    font-size: 14px;
  }

  .post-job>button {
    font-size: 15px;
  }

  .login-signup>button {
    font-size: 15px;
  }

  .logo>img {
    width: 200px !important;
  }
     .menu-bar {
       justify-content: flex-start;
     }
}

@media (max-width: 1070px) {
  .menubar {
    /* position: absolute;
    left:30px; */
    font-size: 50px;
    display: block;
  }
     .buttons {
       display: none;
     }
         .login-signup>button {
           font-size: 15px;
         }
.nav {
  height: 100%;
  display: block;
    margin-right: 5px;
      padding: 10px;

}
  .menu-bar {
    display: block;
    font-size: 60px;
    justify-content: flex-start;
  }

  .links {
    display: none;
  }

  .nav-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid gray;
    margin-top: -10px;
  }

  .nav-links>li {
    line-height: 50px;
  }

  .logo>img {
    margin-left: 70px;
  }
}
.dlogo {
  width: 200px !important;

}

@media (max-width: 768px) {
  .nav {
      height: 100%;
     display: block;
     margin-right: 5px;
     padding: 10px;
  
      
    }
        .dlogo {
          width: 200px !important;
    
        }
        .menu-logo {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
                 .menu-bar {
                   justify-content: flex-start;
                 }
  .nav-links>li {
    font-weight: 500;
    font-size: 13px;
    line-height: 37px;
    margin: 1 10px;
 
    
  }
     .buttons {
      display:none;
     }

  .post-job>button {
    font-size: 12px;
    padding: 6px 12px;
  }


  .userName {
    font-size: 12px;
  }

  .logo img {
    width: 95px;
    margin-left: 5px;
  }

  /* .nav{
    justify-content: space-evenly;
  } */
}

@media (max-width: 400px) {
  .post-job>button {
    font-size: 10px;
    padding: 0px 5px;
  }
  .dlogo{
    width: 200px !important;
  
  }
    .nav-toggle {
      cursor: pointer;
      font-size: 20px;
    }

  .menu-bar{
    justify-content: flex-start;
  }
  
.nav {
  height: 100%;
  display: block;
  margin-right: 5px;
    padding: 10px;
}
 
 .buttons{
  display: none;
 }
 

  }

  .search-noti>img {
    display: none;
  }


/* Hire expert container */
.popular-search {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.popular-search>h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #0d0d0dce;
  margin: 0;
}

.btn-popular {
  margin: 0;
  line-height: 0;
}

.btn-popular>button {
  color: var(--primary-green);
  background-color: #cef0e4;
  font-family: "Poppins";
  border-radius: 20px;
  border: none;
  padding: 3px 13px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.btn-popular>strong {
  color: var(--primary-green);
  background-color: #cef0e4;
  border-radius: 20px;
  border: none;
  padding: 7px 20px;
  font-size: 12px;
  height: 30px;
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.rectangle-parent3 {
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.find-hire-expert-freelancer-parent {
  color: #0d0d0d;
  font-family: Poppins;
  background: #f5deb347;
}

.find-hire-expert-freelancer-parent-child1 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 500px;
}

.find-hire-container {
  text-align: left;
  font-size: 56px;
  margin-bottom: 10px;
}

.heroimg {
  width: 500px;
}

.heroimg>img {
  width: 100%;
}

.skills-jobs {
  display: flex;
  align-items: center;
}

.button-post {
  width: 30%;
  height: 49px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 15px; */
  margin-bottom: auto;
  margin-top: 2px;
}

.input {
  width: 400px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin: 0 10px;
}

.input>input {
  width: 350px;
  height: 24px;
  color: #b9b9b9;
  font-size: large;
  outline: none;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 1300px) {
  .input {
    width: 300px;
    height: 60px;
    margin-left: 20px;
  }

  .input>input {
    width: 250px;
    height: 24px;
  }

  .button-post {
    width: 200px;
    height: 49px;
    font-size: 16px;
    line-height: 24px;
  }

  .find-hire-container {
    line-height: 60px;
    font-weight: 600;
    margin-left: 20px;
  }

  .work-with-the-container {
    font-size: 14px;
    font-weight: 500;
    color: #606563;
    margin-bottom: 10px;
    margin-left: 20px;
  }

  .heroimg {
    width: 400px;
  }
}

/* Job post filter */

.rectangle-3-img {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url("./images/backimg.jpg");
  color: white;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  /* background-attachment: fixed; */
  background-size: cover;
  /* transform: matrix(1,0 , 0, 1, 0, 0); */
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  padding: 80px;
}

.contain>h2 {
  font-family: Poppins;
  font-size: 56px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
  width: 491px;
  height: 140px;
}

.contain>p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  width: 516px;
  height: 90px;
}

.job-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 51px;
}

@media (max-width: 1300) {
  .job-cards {
    margin-left: 80px;
  }

  .contain>h2 {
    margin-left: 80px;
  }

  .contain>p {
    margin-left: 80px;
  }
}

/* Job container.css */
.rectangele-2-container {
  background-color: var(--primary-green);
  display: flex;
  flex-direction: column;
  width: 390px;
  border-radius: 6px;
  padding: 31px 17px 17px 17px;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: ease-in-out 0.2s;
}

.rectangele-2-container:hover {
  background-color: white;
  color: var(--primary-green);
}

.heading {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  width: 59%;
  margin-bottom: 31px;
}

.subcontent {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
}

.subcontent>svg {
  padding-left: 20px;
}

@media (max-width: 1300px) {
  .heading {
    width: 70%;
    font-size: 28px;
  }

  .subcontent {
    font-size: 16px;
  }
}

@media (max-width: 1070px) {
  .contain>h2 {
    font-size: 50px;
  }

  .contain>p {
    font-size: 18px;
  }

  .heading {
    width: 70%;
    font-size: 24px;
  }

  .subcontent {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .job-cards {
    flex-direction: column;
  }

  .contain>h2 {
    width: 282px;
  }

  .contain>p {
    width: 320px;
  }
}

@media (max-width: 450px) {
  .rectangele-2-container {
    width: 90%;
  }

  .contain>h2 {
    width: 70%;
    height: fit-content;
  }

  .contain>p {
    width: 80%;
    height: fit-content;
  }

  .rectangle-3-img {
    padding: 30px;
  }
}

/* Job posting container .css */
.rectangle-parent-cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding: 20px 20px 62px 20px; */
  /* padding: 20px; */
  border-radius: 7px;
  box-sizing: border-box;
  margin-left: 20px;
}

.icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  content: jobs;
  background-color: rgba(96, 208, 147, 0.199);
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon>img {
  width: 70px;
  height: 70px;
}

.job-heading {
  width: 286px;
  height: 30px;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.job-description {
  width: 285px;
  height: 90px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #606563;
  margin-bottom: 10px;
  box-sizing: border-box;
}

@media (max-width: 1300px) {
  .its-easy-to {
    font-size: 40px;
  }

  .jobs-card {
    margin: 30px 50px;
  }

  .rectangle-parent-cards {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 22px 80px 23px;
    width: 240px;
    height: 367px;
    border-radius: 7px;
    box-sizing: border-box;
    margin: 10px 5px;
  }

  .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    content: jobs;
    background-color: rgba(96, 208, 147, 0.199);
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon>img {
    width: 58px;
    height: 58px;
  }

  .job-heading {
    width: 196px;
    height: 30px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }

  .job-description {
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #606563;
    margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 5px;
  }
}

@media (max-width: 1070px) {
  .rectangle-parent-cards {
    width: 350px;
    padding: 5px;
    height: auto;
  }

  .jobs-card {
    flex-wrap: wrap;
  }

  .its-easy-to {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .rectangle-parent-cards {
    width: 350px;
  }

  .its-easy-to {
    font-size: 30px;
    line-height: normal;
  }
}

@media (max-width: 400px) {
  .rectangle-parent-cards {
    width: 350px;
    margin: 10px 15px;
  }

  .its-easy-to {
    font-size: 30px;
    line-height: normal;
    padding: 30px;
  }
}
 .menu-bar {
   justify-content: flex-start;
 }

/* section details */
.why-section {
  margin: auto;
  margin-top: 20px;
  max-width: 1300px;
  position: relative;
}

.why-section-content {
  display: flex;
  justify-content: space-between;
}

.why-img>img {
  width: 78%;
  padding-top: 20px;
  z-index: 199;
  position: relative;
  margin-left: -107px;
}

.points {
  border-radius: 0 10px 10px 0;
  padding: 40px 20px 20px 100px;
  width: 60%;
  margin-top: 40px;
}

.points-num {
  font-size: 92px;
  width: 32%;
  text-align: center;
  color: #d5e2de;
}

.points-icon {
  display: flex;
  justify-content: center;
}

.points-icon>img {
  margin-top: 18px;
}

.why-section-head {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  width: 80%;
  margin-bottom: 10px;
}

.points>li {
  width: 88%;
  color: black;
  opacity: 1;
  list-style-type: none;
  display: grid;
  grid-template-columns: 100px 100px 54%;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  margin-block: 16px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
}

.points>li>div>p {
  font-family: Poppins;
  margin-top: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 101, 99, 1);
}

.why-img {
  width: 40%;
}

.why-section-box {
  background: rgba(7, 178, 116, 1);
  width: 19%;
  /* height: 230px; */
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
  top: 61%;
  right: 3%;
  /* left: 68%; */
  z-index: 200;
}

@media (max-width: 1070px) {
  .points {
    padding: 20px;
  }

  .why-section-head {
    font-size: 30px;
  }

  .points>li {
    font-size: 18px;
  }

  .points>li>div>p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .points {
    width: 100%;
  }

  .why-section-head {
    font-size: 28px;
  }

  .points-num {
    font-size: 60px;
  }

  .points-icon>img {
    width: 50px;
  }

  .points>li {
    width: 100%;
    font-size: 16px;
    grid-template-columns: 70px 70px 70%;
    gap: 10px;
  }

  .points>li>div>p {
    font-size: 12px;
  }

  .why-section-box {
    display: none;
  }

  .why-img {
    display: none;
  }
}

@media (max-width: 450px) {
  .why-section-head {
    font-size: 24px;
  }

  .points>li {
    margin-top: 30px;
    line-height: 20px;
    grid-template-columns: 60px 60px 64%;
  }

  .points>li>div>p {
    margin-top: 5px;
  }

  .points {
    padding: 10px;
  }

  .why-section-box {
    font-size: 14px;
  }
}
 .menu-bar {
   justify-content: flex-start;
 }

/* show numbers */
.show-number {
  display: flex;
  height: auto;
  background-color: rgb(7 175 114);
  justify-content: center;
  align-items: center;
  padding-block: 2.5rem;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 5px 20px; */
}

.box>div>img {
  width: 70px;
}

.box>svg {
  width: 30px;
  color: #07b273a6;
}

.show-number hr {
  width: 100px;
  transform: rotate(90deg);
  margin: 0;
}

.count-content {
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  padding-left: 10px;
  /* align-items: center; */
}

.number-head {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.number {
  font-size: 28px;
  font-weight: 600;
  color: white;
}

@media (max-width: 1300px) {
  .number {
    font-size: 24px;
  }
}
.dlogo{
  width: 200px !important;
}

@media (max-width: 1070px) {
  .box {
    margin: 5px 10px;
  }

  .box>img {
    width: 20px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .show-number {
    flex-wrap: wrap;

    &>hr {
      display: none;
    }
  }
    .dlogo {
      width: 200px !important;
    }

  .box {
    width: 40%;
  }


    .menubar{
      
      display: flex;
      justify-content: flex-start;
     
    }

  .box>div>img {
    width: 50px;
  }

  .number {
    font-size: 18px;
  }

  .number-head {
    font-size: 12px;
  }
}

/* why digilancer.css */
.why-digilancer {
  padding: 26px 100px;
  background: rgba(128, 128, 128, 0.055);
  padding-block-end: 100px;
}

.home-howitwork {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin: 15px 45px;
}

.home-howitwork a {
  font-size: 14px;
  font-weight: 600;
}

.Apart {
  margin-top: 15px;
  color: white;
  background-color: var(--primary-green);
  text-align: center;
  padding: 0 100px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Apart>p {
  margin-top: 0;
  font-size: 20px;
  line-height: 30px;
}

.Apart>h1 {
  margin-top: 20px;
  font-size: 46px;
  line-height: 70px;
}

.Apart-points {
  display: flex;
  padding: 20px 50px;
  justify-content: space-between;
  border: 1px solid rgba(128, 128, 128, 0.153);
  margin-top: 25px;
  border-radius: 20px;
  background: white;
  align-items: center;
}

.Apart-containet {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 30px;
}

.aprt-containet-head {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  padding-right: 20px;
  /* identical to box height */
  color: #000000;
}

.aprt-containet-value {
  color: rgba(96, 101, 99, 1);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-top: 10px;
}

.apart-img {
  width: 40%;
}

.apart-img>img {
  width: 85%;
}

@media (max-width: 1300px) {
  .aprt-containet-head {
    font-size: 18px;
  }

  .aprt-containet-value {
    font-size: 14px;
  }

  .Apart-containet {
    width: 55%;
  }
}

@media (max-width: 1070px) {
  .Apart-points {
    flex-direction: column-reverse;
  }

  .Apart-containet {
    width: 90%;
  }

  .apart-img {
    width: 50%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .why-digilancer {
    margin: 12px 30px;
  }

  .apart-img {
    width: 90%;
    margin: auto;
  }
    .dlogo {
      width: 200px !important;
    }

  .points>li {
    grid-template-columns: 60px 60px 65%;
  }
}

/* why HowItWorks.css */
.HowItWorks {
  margin: 26px auto;
  position: relative;
  min-height: 100vh;
  padding-inline: 20px;
  max-width: 1300px;
  padding-block-end: 50px;
}

.howItWorks {
  padding: 26px 100px;
  position: relative;
  min-height: 100vh;
  padding-block-end: 50px;
  background: rgba(128, 128, 128, 0.055);
}

.Apart-button {
  display: flex;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.Apart-button>div {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: center;
  padding: 5px 29px;
  border-radius: 40px;
  cursor: pointer;
}

.Apart-button-click {
  background-color: var(--primary-green);
  color: white;
  cursor: pointer;
}

.sub-buttons {
  display: flex;
  justify-content: center;
}

.sub-buttons>ul {
  list-style-type: none;
  display: flex;
}

.sub-buttons>ul>li {
  padding: 10px 20px;
  border-bottom: 5px solid #f8f8f8;
}

.sub-buttons>ul>li:hover {
  border-bottom: 5px solid var(--primary-green);
  color: var(--primary-green);
  font-weight: 500;
  cursor: pointer;
  z-index: 1;
}

.hr {
  position: relative;
  top: -27px;
  border-top: 1px solid rgba(211, 203, 203, 0.445);
}

.sign-up-content {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 101, 99, 1);
  width: 65%;
}

.sign-up-points {
  border-radius: 20px;
  border: 1px solid rgba(211, 203, 203, 0.445);
  width: 60%;
  margin: 10px;
  padding: 10px;
  background: white;
}

.sign-up-content-img {
  display: flex;
  justify-content: space-between;
}

.sign-up-img>img {
  width: 80%;
}

.sign-up-points-numbers {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
}

.sign-up-points-numbers>.number {
  font-size: 20px;
  background-color: #97dbc282;
  color: var(--primary-green);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  box-sizing: border-box;
  margin-top: 5px;
}

.sign-up-points-numbers h3 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0em;
}

.sign-up-points-numbers p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 101, 99, 1);
}

.points-links {
  color: var(--sec-green) !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .Apart>h1 {
    margin-top: 20px;
    font-size: 36px;
    line-height: 50px;
  }

  .Apart>p {
    margin-top: 0;
    font-size: 16px;
    line-height: 30px;
  }

  .SignUpEmployee>h1 {
    font-size: 24px;
  }

  .sign-up-content {
    font-size: 14px;
  }

  .sign-up-points-numbers h3 {
    font-size: 16px;
  }

  .sign-up-points-numbers p {
    font-size: 14px;
  }
}

@media (max-width: 1070px) {
  .sign-up-content-img {
    flex-direction: column-reverse;
  }

  .sign-up-img>img {
    width: 60%;
  }

  .sign-up-points {
    width: 90%;
    margin: 0;
  }

  .sign-up-points-numbers>.number {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 768px) {
  .sign-up-img>img {
    width: 80%;
  }

  .sign-up-points {
    width: 100%;
  }

  .Apart {
    padding: 0 70px;
  }

  .sign-up-points-numbers h3 {
    line-height: 35px;
  }

  .sign-up-points-numbers>.number {
    margin-top: 15px;
  }
}
.dlogo {
  width: 200px !important;
}

@media (max-width: 500px) {
  .Apart>h1 {
    font-size: 30px;
    width: 100%;
  }
      

  .Apart {
    padding: 0 30px;
  }

  .HowItWorks {
    padding-inline: 3px;
  }
}

@media (max-width: 450px) {
  .Apart-button {
    flex-direction: column;
    border-radius: 10px;
  }

  .sub-buttons>ul {
    font-size: 14px;
  }

  .sub-buttons>ul>li {
    padding: 10px;
  }

  .SignUpEmployee {
    font-size: 14px;
  }
    .dlogo {
      width: 200px !important;
    }

  .sign-up-content {
    font-size: 14px;
  }

  .sign-up-points-numbers>h3 {
    font-size: 16px;
    line-height: 24px;
  }

  .Apart-button>div {
    border-radius: 10px;
  }
}

/* hire Freelancer */
.Hire-Apart {
  margin-top: 15px;
  background: rgba(255, 228, 149, 1);
  border-radius: 20px;
  height: fit-content;
  display: flex;
}

.hire-Apart-text {
  width: 70%;
  padding-left: 50px;
  padding-top: 50px;

  &>h1 {
    margin-bottom: 0;
  }

  &>p {
    margin-top: 0;
  }
}

.hire-Apart-img>img {
  height: 200px;
  position: relative;
  bottom: -18px;
  /* margin-top: 37px; */
}

.pagination-fiter-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.filter-table-container {
  padding-right: 50vw;
  padding-top: 25px;
  padding-bottom: 15px;
}

.filter-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 15px;
}

.filter-sort>div {
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 11px 5px;
  margin-left: 15px;
  display: flex;
  border-radius: 3px;
  align-items: center;
  width: 100px;
  justify-content: space-around;
}

.filter span {
  font-size: 16px;
  font-weight: 500;
}

.filter svg {
  width: 19px;
}

/* card css */
.freelancers {
  display: flex;
  flex-wrap: wrap;
  /* margin-inline: auto; */
  justify-content: center;
}

.freelancers-card {
  outline: 1px solid rgba(228, 228, 228, 1);
  outline: 1px solid rgba(228, 228, 228, 1);
  outline-offset: 4px;
  outline: 1px solid rgba(228, 228, 228, 1);
  outline-offset: 4px;
  width: 28%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 2%;
  padding: 8px 0;
  background: rgb(255, 255, 255);
}

.freelancers-card:hover {
  box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 20px -3px;
}

.freelancers-card>img {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 50%;
}

.freelancers-card .skills {
  margin-top: 0;
}

.imglike>img {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 28px;
  cursor: pointer;
}

.potfoliothumbail:hover {
  opacity: 0.5;
}

.deleteiconPotfolio {
  cursor: pointer;
  display: none;
  position: absolute;
  top: -10px;
  right: -5px;
  stroke-width: 1px;
}

.potfolioConatiner a:hover~.deleteiconPotfolio,
.deleteiconPotfolio:hover {
  display: block !important;
}

.skills {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  align-items: center;
  color: var(--primary-green);
  margin-top: 20px;
}

.skills-employee {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--primary-green);
  margin-top: 5px;
}

.skills>button {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin: 5px;
  padding: 5px 16px;
}

.skills-employee>strong {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  margin: 5px;
  padding: 5px 16px 7px 17px;
}

.new-skills-tag {
  color: rgb(0, 0, 0);
  border-radius: 15px;
  border: 1px solid rgba(198, 187, 187, 0.53);
  padding: 5px 10px;
}

.line {
  width: 90%;
  border-top: 1px solid rgba(128, 128, 128, 0.217);
}

.location-content {
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
}

.location-content>div {
  display: flex;
  flex-direction: column;
  margin: 5px 20px;
}

.view-profile {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  color: #0dcd86;
  /* margin-top: 10px; */
  cursor: pointer;
}

.view-profile>img {
  width: 40px;
  cursor: pointer;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
}

.profession {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(97, 119, 152, 1);
}

.rating {
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}

.rating p {
  font-size: 14px;
  color: #979797;
  font-weight: 100;
  padding-left: 3px;
}

.rating-star {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  margin-top: -2px;
}

.featuredfreelancer-rating-star {
  font-size: 20px;
  color: #ecec0a;
}

@media (max-width: 1300px) {
  .hire-Apart-text>h1 {
    font-size: 28px;
  }

  .hire-Apart-text>p {
    font-size: 14px;
  }

  .hire-Apart-img>img {
    height: 160px;
  }

  .freelancers-card>img {
    width: 90px;
    height: 90px;
  }

  .skills>button {
    font-size: 12px;
  }

  .view-profile>img {
    margin-left: 60px;
    width: 40px;
  }

  .name {
    font-size: 20px;
  }

  .profession {
    font-size: 12px;
  }

  .rating {
    font-size: 12px;
  }

  .location-content>div {
    font-size: 13px;
  }

  .view-profile>img {
    width: 35px;
  }
}

@media (max-width: 1078px) {
  .freelancers-card {
    width: 43%;
  }

  .filter-table-container {
    padding-right: 49vw;
  }
}

@media (max-width: 900px) {
  .filter-table-container {
    padding-right: 40vw;
  }
}

@media (max-width: 768px) {
  .freelancers-card {
    width: 90%;
  }
    .dlogo {
      width: 200px !important;
    }

  .Hire-Apart {
    height: fit-content;
    flex-wrap: wrap;
  }

  .hire-Apart-img>img {
    width: 80%;
    height: auto;
    margin-top: 37px;
    margin-left: 20px;
  }
}

@media (max-width: 500px) {
  .HowItWorks {
    margin: 12px 30px;
  }

  .pagination-fiter-table {
    justify-content: center;
  }

  .filter-sort {
    margin-top: 10px;
    padding: 0;
  }

  .filter-sort>div {
    padding: 0;
  }

  .filter {
    &>svg {
      width: 12px;
    }

    &>span {
      font-size: 12px;
      
    }
  }

  .select-type>div>span {
    font-size: 12px;
  }
}

/* Project card */
.project-card {
  border: 1px solid rgba(228, 228, 228, 1);
  width: 31%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.project-card>img {
  width: 100%;
}

.project-card button {
  margin-top: 20px !important;
}

.project-card button,
.project-card h3,
.project-card span {
  margin: 8px 20px;
  border-radius: 0%;
}

.price-img {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.price-img>img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

@media (max-width: 1300px) {
  .project-card>h3 {
    font-size: 16px;
  }
}



@media (max-width: 1070px) {
  .project-card {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .price-img>img {
    width: 50px;
    height: 50px;
  }

  .price-img>span {
    font-size: 14px;
  }
}

/* hireExpertConatiner*/
.HireExpertConatiner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(208 241 230);
  padding: 100px;
}

.hireExpertContainerImage {
  width: 50%;
}

.hireExpertContainerImage>img {
  width: 100%;
}

.hireExpertContainer-content-heading>h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 70px;
  margin: 0;
}

.hireExpertContainer-content>p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: rgba(96, 101, 99, 1);
  width: 90%;
}

.input-skills {
  background-color: white;
  color: black;
  width: 300px;
  height: 80px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-right: 15px;
}

.input-skills>input {
  outline: none;
  border: none;
  width: 240px;
  font-size: 16px;
  padding-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
}

@media (max-width: 1300px) {
  .hireExpertContainerImage>img {
    width: 85%;
  }

  .HireExpertConatiner2 {
    padding: 80px 40px;
  }

  .hireExpertContainer-content-heading>h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    margin: 0;
  }

  .hireExpertContainer-content>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: rgba(96, 101, 99, 1);
    width: 90%;
  }

  .input-skills {
    background-color: white;
    color: black;
    width: 300px;
    height: 60px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-right: 15px;
  }

  .skills-jobs>p {
    font-size: 20px;
  }
}

@media (max-width: 1070px) {
  .HireExpertConatiner2 {
    padding: 40px 70px;
  }

  .hireExpertContainer-content-heading>h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    margin: 0;
  }

  .hireExpertContainer-content>p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: rgba(96, 101, 99, 1);
    width: 90%;
    margin-top: 5px;
  }

  .input-skills {
    background-color: white;
    color: black;
    width: 230px;
    height: 60px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-right: 10px;
  }

  .input-skills>input {
    outline: none;
    border: none;
    width: 90%;
    font-size: 14px;
    padding-left: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
  }

  .button-post {
    width: 200px;
    height: 49px;
    font-size: 14px;
    line-height: 24px;
  }

  .skills-jobs>p {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .loginLogo{
    display: none;
  }
  .HireExpertConatiner2 {
    flex-direction: column;
    justify-content: center;
    padding: 40px 40px;
  }

  .hireExpertContainerImage {
    margin-top: 20px;
    width: 70%;
  }

  .hireExpertContainer-content {
    width: 90%;
  }

  .hireExpertContainer-content-heading>h1 {
    font-size: 26px;
  }

  .input-skills {
    background-color: white;
    color: black;
    width: 230px;
    height: 60px;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-right: 10px;
  }

  .input-skills {
    width: 40%;
  }

  .input-skills>input {
    width: 90%;
    height: 40px;
    font-size: 12px;
    margin: 5px;
  }

  .button-post {
    width: 40%;
    height: 40px;
    font-size: 12px;
    padding: 10px;
  }
}

@media (max-width: 400px) {
  .HireExpertConatiner2 {
    padding: 30px 30px;
  }

  .hireExpertContainer-content>p {
    width: 100%;
  }

  .skills-jobs {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .skills-jobs>p {
    margin: 0;
    font-size: large;
  }

  .input-skills {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    margin: 0;
  }

  .input-skills>input {
    width: 90%;
    height: 40px;
    font-size: 12px;
    box-sizing: border-box;
  }

  .button-post {
    width: 100%;
    height: 45px;
    font-size: 12px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0;
  }
}

/* find job/published job/listed job */

.findJobHead {
  display: flex;
  flex-direction: column;
  background: rgba(252, 250, 244, 1);
  padding: 25px;
}

.findJobHead>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.findJobHead>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.findJobHead>div>input[type="text"] {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  padding: 23px 19px;
  width: 70%;
  box-sizing: border-box;
  margin-right: 10px;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b9b9b9;
}

.findJobHead>div>button {
  width: 21%;
  box-sizing: border-box;
  padding: 23px 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}

.joblisted {
  margin: 30px 0;
}

.joblisted {
  display: flex;
  justify-content: center;
}

.jobfilter {
  display: flex;
  flex-direction: column;
  width: 340px;
  align-self: stretch;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  height: fit-content;
  border-radius: 5px;
  box-sizing: border-box;
}

.currentjob {
  width: 1000px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  box-sizing: border-box;
}

.select-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 16px 20px;

  &>div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .filter-sort {
    display: none;
  }

  & span {
    display: flex;
    font-weight: 500;
    color: #5b5b5b;
    font-size: 16px;
    align-items: center;

    &>p {
      font-size: 18px;
      margin: 0;
      font-weight: 600;
    }
  }
}
.menu-bar{
  display: flex;
  
 
  padding:2rem;
  background-color: white;

padding-top: 10px;
justify-content: center;
align-items: center;
gap:10px;
font-size:10px;
transition: all 0.4s ease;

}
.nav-links
{
  display:flex;
  justify-items: left;


padding: 2px;
gap:2px;
transition:  0.4s ease;


}

.currentb>.select-type>select {
  width: fit-content;
  padding: 14px 17px;
}

.currentjob>.select-type>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #404040;
  padding-left: 10px;
}

.alljobs {
  padding: 0 20px;
}

.jobfilter {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.jobfilter>hr {
  width: 100%;
  margin: 0;
}

.filterClear {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  padding-top: 0;
}

.filterHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.clearHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-green);
  cursor: pointer;
}

.filterType>.budgetHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 18px 20px 17px 20px;
}

.filterType>div {
  display: flex;
  flex-direction: column;
  padding: 18px 20px 17px 20px;
}

.filterType>div>div {
  display: flex;
}

.filterType>div>select {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  padding: 10px;
}

.filterinput {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}

.filterinput>input {
  width: 40%;
  padding: 10px 4px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.checkboxfilter>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.checkboxfiler-apply {
  padding-inline: 10px;
  position: sticky;
  bottom: 0;
  background: #fffffffb;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.164);
}

.checkboxfilter-apply-btn {
  border-radius: 0px;
  justify-content: center;
  padding: 10px 20px;
  background: rgba(7, 178, 116, 1);
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.checkboxfilter>div {
  flex-direction: column;
}

.checkboxfilter>div>label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* color: #404040; */
  color: #707070;
  margin: 10px 0 0 5px;
  display: flex;
  align-items: center;
}

.filterType input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.typeCheckbox {
  margin-bottom: 5px;
}

.typeCheckbox>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

@media (max-width: 1300px) {
  .findJobHead {
    display: flex;
    flex-direction: column;
    background: rgba(252, 250, 244, 1);
    padding: 25px;
  }

  .findJobHead>span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
  }

  .findJobHead>div>input[type="text"] {
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    padding: 16px;
    /* width: 38%; */
    box-sizing: border-box;
    margin-right: 10px;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b9b9b9;
  }

  .findJobHead>div>button {
    width: 21%;
    box-sizing: border-box;
    padding: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height */
    text-align: center;
    color: #ffffff;
  }

  .joblisted {
    margin: 30px 0;
  }

  .joblisted {
    display: flex;
    justify-content: space-between;
  }

  .jobfilter {
    width: 28%;
  }

  .currentjob {
    width: 70%;
    height: fit-content;
    background: #ffffff;
    border: 1px solid #c6c6c6;
    border-radius: 5px;

    box-sizing: border-box;
  }

  .currentjob>.select-type>select {
    width: fit-content;
    padding: 16px 14px;
    background: #ffffff;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
  }

  .currentjob>.select-type>span {
    font-size: 14px;
  }

  .alljobs {
    padding: 0 20px;
  }

  .jobfilter {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .jobfilter>hr {
    width: 100%;
    margin: 0;
  }

  .filterClear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px;
  }

  .filterHead {
    font-size: 20px;
    line-height: 10px;
  }

  .clearHead {
    font-size: 14px;
    line-height: 0px;
  }

  .filterType>.budgetHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 0;
    padding: 14px 18px 13px 14px;
  }

  .filterType>div {
    display: flex;
    flex-direction: column;
    padding: 14px 18px 13px 14px;
  }

  .filterType>div>div {
    display: flex;
  }

  .filterType>div>select {
    font-size: 12px;
  }

  .filterinput {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }

  .filterinput>span {
    font-size: 12px;
  }

  .filterinput>input {
    width: 40%;
    padding: 7px 4px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 3px;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
  }

  .checkboxfilter>span {
    font-size: 16px;
  }

  .checkboxfilter>div {
    flex-direction: column;
  }

  .checkboxfilter>div>label {
    margin: 6px 0 0 5px;
    font-size: 14px;
  }

  .filterType input[type="checkbox"] {
    width: 17px;
    height: 17px;
  }

  .typeCheckbox {
    margin-bottom: 10px;
  }

  .typeCheckbox>span {
    font-size: 14px;
  }
}

@media (width < 1019px) {
  .joblisted .jobfilter {
    display: none;
  }

  .select-type>.filter-sort {
    display: flex;
  }

  .joblisted .currentjob {
    width: 100%;
  }
}

@media (width< 768px) {
  .findJobHead>div>button {
    width: 31%;
  }

  /* .findJobHead>div>input[type="text"] {
    width: 64%;
  } */
}

@media (width< 400px) {
  .findJobHead>div {
    flex-direction: row;
    align-items: flex-start;
  }

  .findJobHead>div>input[type="text"] {
    width: 100%;
    margin-bottom: 5px;
  }

  .findJobHead>div>button {
    padding: 8px 12px;
  }
}

/* user Profile */

.profile-Apart {
  margin-top: 15px;
  background: rgba(149, 255, 204, 0.15);
  border-radius: 20px;
  height: 250px;
  display: flex;
  align-items: center;
  padding: 60px;
  box-sizing: border-box;
}

.profile-Apart>img,
.profile-Apart .profile-Apart-inner>img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.logedUser-section {
  display: flex;
  justify-content: space-between;
}

.user-name {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.user-name {
  padding: 15px;
  position: relative;
}

.user-name>h2 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: 0em;
  margin: 0;
}

.user-name>span {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.user-reviews-address {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.user-reviews-address span {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
}

.about {
  margin-top: 33px;
  word-break: break-word;
}

.about>b {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.about>p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  color: rgb(1 1 1 / 70%);
}

@media (width < 1035px) {
  .logedUser-section {
    flex-direction: column;
  }

  .profile-Apart>img,
  .profile-Apart .profile-Apart-inner>img {
    width: 100px;
    height: 100px;
  }

}

.rate-card {
  /* width: 30%; */
  border: 1px solid rgb(210 210 210);
  border-radius: 20px;
  padding: 25px 20px;
  margin-top: 15px;
  position: relative;
  /* top: -7vh; */
  /* right: 0px; */
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 11px 40px rgba(146, 146, 146, 0.1);
}

.rate {
  font-weight: 600;

  & span {
    padding-right: 10px;
  }
}

.price-range {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.price-range>img {
  width: 70px;
}

.hour-price {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.hour-price>.rate {
  font-family: Poppins;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
}

.hour-price>span {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0px;
}

.user-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}

.user-details>p {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(97, 119, 152, 1);
  margin: 8px 0;
}

.user-details>span {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
}

.Portfolio-docs {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  width: 77%;
  height: 90px;
  overflow-x: auto;
}

.hire-button {
  display: flex;
  justify-content: center;
}

.hire-button>button {
  width: 80%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  padding: 17px 0px;
  margin-top: 50px;
}

.about-rate {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.employer-about-rate {
  min-height: 500px;
}

.education {
  width: 60%;
  margin: 30px 0 55px;
}

.all-education {
  display: flex;
  margin-top: 20px;
}

.education>strong {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
}

.education>strong>img {
  width: 35px;
  margin-left: 10px;
}

.education-line {
  align-items: stretch;
  /* background-color: black; */
  margin-right: 20px;
  border-right: 3px dashed #aaf2d8;
}

.education-content {
  display: flex;
}

.eduction-time {
  margin-bottom: 15px;
}

.first-later {
  background-color: #5be0af38;
  color: var(--sec-green);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.year-range {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  background: #dbf4eb;
  width: fit-content;
  padding: 2px 12px;
  border-radius: 31px;
}

.collage {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-block: 10px;
}

.eduction-time {
  display: flex;
  flex-direction: column;
}

.eduction-time p {
  font-family: Poppins;
  font-size: 14px;
  /* font-weight: 500; */
  margin: 0;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 101, 99, 1);
}

.hover-text{
  visibility: hidden;
  width: 60px; /* Adjust width as needed */
  background-color: #555; /* Background color of the tooltip */
  color: #fff; /* Text color */
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the image */
  margin-left: -30px; /* Center the tooltip */
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.edit-icons:hover .hover-text{
  visibility: visible;
  opacity: 1;
}

.edit-icons {

  justify-content: flex-end;
  margin-left: 470px;
  display: flex;
}

.edit-icons>img {
  height: 20px;
  width: 20px;
  margin-right: 0px;
}

.skillset {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.skillset>strong {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.skillset>b {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
}

.skillset>small {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  color: #606563;
}

.takeSkillTest-container {
  display: flex;
  flex-direction: column;
  background-color: #0cfca415;
  box-shadow: 0px 8px 13px rgba(146, 146, 146, 0.1);
  padding: 10px;
  border-radius: 20px;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.takeSkillTest-container span {
  color: var(--primary-green);
  font-size: 14px;
  font-weight: 600;
}

.takeSkillTest-container p {
  color: var(--primary-green);
  font-size: 12px;
}

.skillsset-card {
  margin-top: 40px;
  margin-bottom: 10px;
  width: 30%;
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 20px;
  position: absolute;
  top: 800px;
  right: 0px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 11px 40px rgba(146, 146, 146, 0.1);
}

.technicalset-card {
  margin-top: 20px !important;
  margin-bottom: 10px;
  width: 30%;
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 20px;
  position: absolute;
  top: 93%;
  right: 0px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 5px 20px;
  background-color: white;
  box-shadow: 0px 11px 40px rgba(146, 146, 146, 0.1);
  box-sizing: border-box;
}

.skills-tech-container {
  margin-top: -35px;
  width: 30%;

  & :is(.skillsset-card, .technicalset-card) {
    position: unset;
    width: 100%;
  }
}

.skillsset-card .btn-outline {
  border-radius: 50px;
  width: auto;
  height: auto;
  font-size: 13px;
  /* letter-spacing: 1px; */
  padding: 7px 15px;
  border: 1px solid var(--primary-green);
}

.skillsset-card hr {
  border: 1px solid #80808029;
  width: 100%;
  margin-bottom: 1em;
}

.skillsset-card h5,
.technicalset-card>h5 {
  font-size: 20px;
  margin: 6px 0;
}

.skillsset-card ul li {
  font-size: 12px;
}

.skillsset-card>div>strong,
.technicalset-card>div>strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background: #66d0a951;
  color: var(--sec-green);
  padding: 3px 17px;
  margin: 5px;
  border-radius: 33px;
  display: inline-block;
}

.css-qbdosj-Input {
  width: 300px;
}

.select__indicators {
  display: none !important;
}

/* /freelancer Stats Start/ */
.item {
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 11px;
  border-radius: 20px;
  margin-block: 10px;
}

.item:hover {
  transform: scale(0.95);
  transition: all 0.3s ease;
}

.item svg {
  width: 40px;
  height: 30px;
}

.item--1 {
  background: #c7c7ff;
}

.item--2 {
  background: #ffd8be;
}

.item--3 {
  background: #a9ecbf;
}

.item--4 {
  background: #c4dcf8;
}

.quantity {
  font-size: 20px;
  font-weight: 600;
}

.text {
  font-size: 12px;
  font-family: inherit;
  font-weight: 600;
}

.text--1 {
  color: rgba(149, 149, 255, 1);
}

.text--2 {
  color: rgba(252, 161, 71, 1);
}

.text--3 {
  color: rgba(66, 193, 110, 1);
}

.text--4 {
  color: #69aaf9;
}

.profile_skill_tech_container {
  width: 30%;
}

.profile_skill_tech_container .profile-rate-card {
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 11px 40px rgb(146 146 146 / 10%);
}

/* /freelancer Stats End/ */

@media (max-width: 1300px) {
  .user-name>h2 {
    font-size: 24px;
    line-height: 56px;
  }

  .user-name>h2>svg {
    font-size: 20px;
    line-height: 56px;
  }

  .user-name>span {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }

  .rating {
    align-self: center;
  }

  .rate-card {
    width: 300px;
  }

  .hour-price>.rate {
    font-size: 16px !important;
  }

  .hour-price>span {
    font-size: 18px;
  }

  .user-details>p {
    font-size: 12px;
  }

  .user-details>span {
    font-size: 12px;
  }

  .about>b {
    font-size: 20px;
  }

  .education>strong {
    font-size: 20px;
  }

  .year-range {
    font-size: 12px;
  }

  .collage {
    font-size: 15px;
  }

  .eduction-time>p {
    font-size: 14px;
  }

  .skillset>strong {
    font-size: 20px;
  }

  .skillset>b {
    font-size: 16px;
    line-height: 35px;
  }

  .skillset>small {
    font-size: 15px;
  }

  .skillsset-card,
  .technicalset-card {
    width: 300px;
  }

  .skillsset-card>div,
  .technicalset-card>div {
    margin: 0px;
  }

  .css-b62m3t-container {
    width: 80%;
    height: fit-content;
  }
}

@media (max-width: 1070px) {
  .about-rate {
    flex-direction: column;
  }

  .about {
    width: auto;
  }

  .rate-card {
    position: static;
    top: auto;
    right: auto;
    width: 95%;
    height: auto;
    margin-inline: auto;
    margin-top: 0;
  }

  .skillsset-card,
  .technicalset-card {
    position: relative;
    top: auto;
    right: auto;
  }

  .skills-tech-container {
    position: relative;
    top: auto;
    right: auto;
    width: 80%;
    margin-inline: auto;
    margin-top: 0;
  }

  .education {
    width: auto;
  }

  .user-name>h2 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .profile-Apart {
    height: fit-content;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .user-name {
    justify-content: center;
    align-items: center;
  }

  .user-name>h2 {
    text-align: center;
  }

  .user-name>span {
    text-align: center;
  }

  .skills-tech-container {
    width: 100%;
  }

  .edit-icons {
    margin-left: 50vw;

    &>img {
      width: 32px;
      height: 32px;
    }
  }
}

@media (max-width: 500px) {
  .user-name>h2 {
    font-size: 18px;
    line-height: normal;
  }
    .dlogo {
      width: 200px !important;
    }

  .user-name>span {
    font-size: 16px;
  }

  .skillsset-card,
  .technicalset-card {
    height: fit-content;
    width: fit-content;
  }

  .skillsset-card>div>strong,
  .technicalset-card>div>strong {
    font-size: 12px;
  }

  .user-details>p {
    font-size: 12px;
  }

  .user-details>span {
    font-size: 12px;
  }

  .hire-button>button {
    line-height: 14px;
    font-size: 12px;
  }

  .hour-price>.rate {
    font-size: 12px !important;
  }

  .hour-price>span {
    font-size: 14px;
  }

  .price-range>img {
    width: 50px;
  }

  .collage {
    font-size: 14px;
  }

  .eduction-time>p {
    font-size: 14px;
  }

  .skillset>b {
    font-size: 14px;
  }

  .skillset>small {
    font-size: 16px;
  }

  /* .skillsset-card, .technicalset-card {
    display: none;
  } */
  .about>p {
    font-size: 12px;
  }

  .rate-card {
    width: 95%;
  }
}

/* login signup */
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44px;
  background-color: white;
  position: relative;
  top: -70px;
}

.login>.logo {
  margin-bottom: 80px;
}

.login-component {
  display: flex;
  width: 60%;
  max-width: 924px;
  border: 1px solid rgba(241, 241, 241, 1);
  box-shadow: 0px 11px 40px 0px rgba(146, 146, 146, 0.1);
  border-radius: 10px;
}

.login-img {
  display: flex;
  flex-direction: column;
  flex: 4;
  padding: 50px 69px;
  box-sizing: border-box;
}

.login-img>.image>img {
  width: 100%;
}

/* login Carousel */
.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125%;
  margin-bottom: 20px;
}

.slide {
  border-radius: 0.5rem;
  width: 270px;
  height: 245px;
}

.slide-hidden {
  display: none;
}

.arrow {
  display: none;
  /* position: absolute;
  filter: drop-shadow(0px 0px 1px rgb);
  width: 2rem;
  height: 1.2rem;
  color: var(--primary-green);
  font-weight: 600; */
}

.arrow:hover {
  cursor: pointer;
}

.arrow-left {
  left: -1rem;
}

.arrow-right {
  right: -1.8rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: -1.8rem;
}

.indicator {
  background-color: var(--primary-green);
  border: none;
  outline: 1px solid rgb(0 0 0 / 10%);
  margin: 0.2rem;
  cursor: pointer;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  padding: 0;
}

.indicator-inactive {
  background-color: #07b27421;
}

/* Login Carousel End */
.grow-with-digilanxer {
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  margin-bottom: 90px;
}

.login-details {
  display: flex;
  flex-direction: column;
  flex: 5;
}

.login-details>h2 {
  margin-top: 0;
}

.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.image>strong {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 11px;
}

.image>span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.login-details {
  padding: 50px 69px;
}

.login-details>h2 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  text-align: center;
}

.facebooklogin {
  display: flex;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid rgba(241, 241, 241, 1);
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  padding: 10px 0 30px 0;
}

.or-connect {
  text-align: center;
  margin: 18px 5px 0px 5px;
  color: rgba(96, 101, 99, 1);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  position: relative;
  top: -29px;
  z-index: 2;
  /* background-color: white; */
}

.loginemail {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 3px;
  width: 100%;
  margin: 0 0 19px 0;
  padding: 8px;
  box-sizing: border-box;
}

.loginemail>input {
  outline: none;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password>span {
  color: var(--primary-green);
  cursor: pointer;
}

.login-forgot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  margin-bottom: 19px;
}

.login-forgot input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.checkbox {
  display: flex;
  align-items: center;
}

.loginBtn {
  padding: 17px 0;
  text-align: center;
  width: 100%;
}

.button {
  display: flex;
  justify-content: space-between;
}

.button>.btn-outline {
  width: 45%;
  padding: 20px 50px;
  height: auto;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 5px;
}

.button>.btn {
  width: 45%;
  padding: 20px 50px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.selectAccountType {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(96, 101, 99, 1);
}

.type {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.type_job {
  width: 75%;
  display: flex;
  /* justify-content: center; */
  /* padding: 10px 0px; */
  /* flex-direction: column; */
  gap: 20%;
}

.type_job>div {
  white-space: nowrap;
}

.type_job>input {
  margin-left: 20px;
}

.type>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(217, 217, 217, 1);
  width: 180px;
  height: 190px;
}

.type>div>span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(96, 101, 99, 1);
}

.type>div>strong {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.setpassword {
  text-align: center;
  margin: 25px 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(96, 101, 99, 1);
}

.code-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(96, 101, 99, 1);
  margin-top: 8px;
}

.input-code {
  display: flex;
  justify-content: space-around;
  margin-bottom: 39px;
  margin-top: 10px;
}

.input-code>div>input {
  width: 2rem;
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid rgba(7, 178, 116, 1);
  outline: none;
  text-align: center;
  font-size: 20px;
  color: var(--sec-green);
}

.resend-code {
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  color: rgba(7, 178, 116, 1);
}

.resend-code>span {
  cursor: pointer;
  margin: 5px;
}

.verify {
  margin-top: 130px;
}

.typeSelect {
  box-shadow: 0 0 5px #007bff;
}

@media (max-width: 1300px) {
  .login>.logo {
    margin-bottom: 20px;
  }

  .login-component {
    width: 80%;
  }

.d logo{
  width: 200px !important;
}

}

@media (max-width: 1070px) {
  .login-component {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .login-component {
    width: 80%;
    flex-direction: column;
  }

  .login-details {
    padding: 50px 20px;
  }
}

@media (max-width: 500px) {
  .login-component {
    width: 90%;
  }
}

/* forgot password */
.blur {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(13, 13, 13, 0.3);
}

.forgot-password {
  position: fixed;
  z-index: 199;
}

/* model */

.model {
  display: none;
  /* Hidden by default */
}

.model_transform.ed {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 350px;
  height: 100%;
  opacity: 1;
  background: rgba(255, 255, 255, 0.89);
  backdrop-filter: blur(16px);
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: rgba(39, 38, 38, 0.479) 0px 2px 15px 0px;
  scroll-snap-type: none;
  transition: all 0.5s ease;
}

.model_transform {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease;
}

.model_transform.ed::-webkit-scrollbar {
  width: 2px;
}

.model_transform.ed::-webkit-scrollbar-thumb {
  background: var(--sec-green);
}

.model_transform.ed::-webkit-scrollbar-track {
  background: white;
}

.model_transform>.jobfilter {
  border-radius: 0;
  background: none;
}

.model-open {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: scroll;
  /* Enable scroll if needed */
  background: rgba(13, 13, 13, 0.3);
  backdrop-filter: blur(2.5px);
  overflow: -moz-scrollbars-none;
  /* Firefox */
  scrollbar-width: none;
  /* Firefox 64+ */


}

.model-open::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.forgot-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.forgot-modal>p {
  color: #888888;
  margin: 1px;
  font-size: large;
}

.forgot-modal>input {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 3px;
  width: 52%;
  margin: 25px 0px;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
}

.forgot-modal>input::placeholder {
  text-decoration-color: #888888;
}

.forgot-modal>button {
  padding: 10px 20px;
  width: auto;
  height: auto;
  font-size: 14px;
  font-weight: 600;
  margin-block: 10px;
  margin-inline: auto;
}

.forgot-modal>button:hover {
  border: 1px solid var(--primary-green);
}

.forgot-modal span {
  font-weight: 500;
  font-size: 12px;
}

.forgot-modal .loginemail>input {
  font-size: 12px;
  line-height: 0px;
  padding: 0px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  padding: 40px;
  border: 1px solid #888;
  width: 70%;
  border-radius: 20px;
  max-width: 1000px;
}

.lowSection {
  margin-top: 30px;
}

.verification-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #ececec;
  margin: 26px 28px 0 0;
}

.ui.popup {
  z-index: 1900;
  border: 1px solid #d4d4d5;
  max-width: 250px;
  background: #fff;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 12px;
  font-style: normal;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.forgot-modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px;
}

/* Close Button */
.close {
  /* position: relative;
  top:0px;
  right:0px; */
  float: right;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-content label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: #161616;
  line-height: normal;
}

.model-field input,
.model-field textarea,
.model-field select {
  padding: 10px 10px;
  width: 95%;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  font-family: poppins;
}

.model-field select {
  width: 90%;
}

.model-field input[type="submit"] {
  width: 15%;
  outline: none;
  border: none;
  background: rgba(7, 178, 116, 1);
}

.Rate_container {
  display: flex;
  align-items: baseline;
}

.Rate_container label {
  display: block;
}

.exp-form textarea {
  display: block;
}

.name input {
  width: 90%;
  margin-right: 24px;
}

.mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
}

.mobile input {
  width: 90%;
}

.gender {
  margin-left: 1rem;
  display: grid;
}

.gender label {
  display: block;
  line-height: 1;
  margin-bottom: 0;
  margin-right: auto;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: first baseline;
}

.radio>input {
  width: 10%;
  margin: 0;
}

#Address {
  display: block;
}

.address-section label {
  display: block;
}

.address {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-inline-end: 35px;
}

.language>div {
  width: 100% !important;
}

.address select {
  margin-right: 34px;
}

.contaier {
  width: 20%;
}

.zip label {
  display: block;
}

.zip input {
  width: auto;
}

.userprofile textarea {
  margin-bottom: 0;
  padding: 10px;
  width: 95%;
  margin-top: 0;
  font-family: poppins;
}

.modal-content>h2 {
  color: var(--sec-green);
}

.userprofile input,
textarea,
select {
  font-family: poppins;
  border: 1px solid hsl(0, 0%, 84%);
  color: #767676;
}

.radio-inputs-user {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #eee;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 267px;
  font-size: 14px;
  margin-right: 10px;
}

.radio-inputs-user .radio {
  flex: 1 1 auto;
  display: flex;
  margin-bottom: 0;
  text-align: center;
}

.radio-inputs-user .radio input {
  display: none;
}

.radio-inputs-user .radio .status {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: none;

  padding: 0.5rem 2.2rem;

  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio-inputs-user .radio input:checked+.status {
  background-color: rgba(7, 178, 115, 0.725);
  font-weight: 600;
  color: #fff;
}

.userdeatail_container {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  padding: 0 40px;
  margin-bottom: 15px;
}

#rate_type {
  width: 100%;
}

.userdeatail_container input {
  padding: 10px 0 10px 10px;
  border: 1px solid hsl(0, 0%, 84%);
  color: #767676;
  width: 97%;

  background: #ffffff;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.userdeatail_container .radio {
  display: flex;
  justify-content: space-between;
}

.userdeatail_container .radio .radio_span {
  display: flex;
  align-items: center;
}

.userdeatail_container .radio .radio_span label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #8e8e8e;
  line-height: normal;
}

.userdeatail_container .radio .radio_span input {
  margin-bottom: 0;
}

.bid-status .radio-inputs-user {
  width: 175px;
  font-size: 12px;

  & .radio .status {
    padding: 0.4rem 1.2rem;
  }
}

.buy-bid {
  color: rgb(7, 178, 116);
  cursor: pointer;
  margin: 0px 5px;
  border-radius: 50%;
  position: relative;
  /* right: -4px; */
  padding: 0px;
  background-color: rgb(255, 255, 255);
  font-size: 23px;
  transition: all 0.2s ease;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  &:hover {
    padding: 3px;
  }
}

@media (max-width: 1300px) {

  .userprofile input,
  textarea,
  select {
    font-size: 14px;
    font-family: poppins;
  }

  .model_transform.ed {
    width: 280px;
  }
}

@media (max-width: 767px) {
  .modal-content {
    width: 85%;
  }

  .model-field>input[type="submit"] {
    width: 95%;
  }

  .model_transform.ed {
    width: 220px;
  }

  .exp-form>div {
    display: flex;
    flex-direction: column;
  }

  .exp-form>div>div {
    width: 100%;
  }

  .modal-content {
    max-height: 80vh;
    overflow: scroll;
  }
}

.edit-buttons {
  width: fit-content;
  display: flex;
  margin-bottom: 30px;
}

.edit-buttons>div {
  padding: 5px 10px;
  margin-left: 10px;
}

.btn-red {
  background-color: red;
}

.modal-content>h2 {
  font-size: 26px;
  line-height: normal;
}

.container {
  display: flex;
  overflow: scroll;
  min-height: 40px;
  max-width: 100%;
  padding: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-block-start: 45px;
  justify-content: space-between;
}

.cards h5 {
  font-size: x-large;
  margin: 0px;
}

.skillsset-card input,
.technicalset-card input {
  min-width: 50%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
  align-self: center;
}

/* Freelancer Job profile or Employer Job posting Profile */

.welcomeConatiner {
  padding-left: 25px;
  display: flex;
  width: 1000px;
  height: 198px;
  background-color: rgba(245, 248, 250, 1);
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  margin-right: 15px;
}

.welcomeConatiner>div {
  display: flex;
  flex-direction: column;
}

.welcomeConatiner>div>img {
  width: 227px;
}

.welcome-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.welcomeName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
}

.jobContainer.ongoing {
  background-color: #0cfca407;
  border-radius: 5px;
}

.jobContainer {
  position: relative;
  width: 1000px;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #e1dada;
  border-radius: 15px;
  box-sizing: border-box;
  background: white;
}

.jobContainer>hr {
  border-top: 1px solid #c6c6c6;
  margin-top: -2px;
}

.jobContainer>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.jobContainer_header>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.jobContainer_header {
  display: flex;
  justify-content: space-between;
}

.jobContainer_header>p {
  color: var(--sec-green);
  cursor: pointer;
}

.job-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-container-header>span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.job-container-header.ongoing>span {
  color: rgba(7, 178, 116, 1);
}

.job-container-header>button {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 20px;
  background-color: rgb(249, 250, 250);
  color: rgba(7, 178, 116, 1);
  border: 1px solid rgba(7, 178, 115, 0.534);
}

.job-container-header>button:hover {
  color: rgb(249, 250, 250);
  background-color: rgba(7, 178, 116, 1);
  border: 1px solid rgba(7, 178, 115, 0.925);
}

.jobProfileContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 50px;
  margin: auto;
  max-width: 1350px;
}

.jobProfileContainer>.compo {
  width: 70%;
  max-width: 980px;
}

.jobProfileContainer>.compo2 {
  width: 25%;
  margin-left: 15px;
  max-width: 350px;
}

.welcomeConatiner {
  width: 100%;
}

.jobContainer {
  width: 100%;
}

.ant-collapse-header-text {
  color: white;
  font-size: 1.5rem;
}

.detail_conatiner p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #787878;
  word-break: break-word;
}

.jobMatch {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 18px;
  margin-inline: -7px;
  line-height: 30px;
  margin-top: 30px;
  transition: all 0.2s ease;
}

.jobMatch-inside {
  display: flex;
  font-style: normal;
  justify-content: center;
  font-size: 13px;
  margin-top: 15px;
  height: 40px;
  background: #efefef;
  justify-self: center;
  border-radius: 50px;
  align-items: center;
}

.jobMatch-inside span {
  padding-inline: 35px;
  color: #0d0d0de0;

  &:hover {
    cursor: pointer;
  }
}

.jobMatch>span {
  color: #5b5b5b;
  border-width: 4px;
  padding: 8px 10px;
  margin-right: 10px;
  line-height: 40px;
  cursor: pointer;

  &:hover {
    color: var(--sec-green);
  }
}

@media (max-width: 970px) {
  .jobMatch-inside {
    font-size: 12px;
    height: 30px;
    flex-wrap: wrap;
    height: fit-content;

    &>p {
      display: none;
    }

    &>span {
      padding-inline: 22px;
      padding-block: 6px;
    }
  }

  .jobMatch {
    font-size: 16px;
  }

  .current-jobs-amount>span {
    font-size: 12px;
    flex-wrap: wrap;
  }
}

.current-jobs {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;

  & .bid-status {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    /* font-weight: 500; */
  }
}

.employerDetail {
  display: flex;
  font-size: 12px;
  gap: 5px;
  font-weight: 500;
}

.employerDetail span {
  float: right;
}

.verified {
  font-size: 15px;
  color: #666565;
}

.current-jobs>div:first-child {
  flex: 8;
  max-width: 95%;
}

.current-jobs>div>.job-name,
.jobName_Dasbord,
.current-jobs>div>div>.job-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
}

.current-jobs-amount {
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: flex-start;
}

.current-jobs-amount>span {
  font-weight: 500;
  color: #606563;
  display: flex;
  font-size: 14px;
  align-items: baseline;
  margin-top: 15px;
}

.current-jobs-amount>span>p {
  margin: 0;
  font-weight: 600;
  color: black;
  font-size: initial;
}

.current-ongoing {
  background-color: aliceblue;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 10px;
  padding: inherit;
}

.current-ongoing>span {
  font-size: x-large;
  color: rgba(7, 178, 116, 1);
  font-weight: 600;
}

.currentongoing {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.3s ease;
}

.currentongoing>div:first-child {
  flex: 8;
}

.currentongoing>div>.job-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  cursor: pointer;
}

.current-ongoing.expand {
  display: none;
}

.currentongoing>hr {
  border-top: 1px solid #f1f1f1;
}

.row-view {
}

.row-view>div {
  width: 45%;
}

.current-jobs>div>p,
.jobName_Dasbord_Paragrap {
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 24px;
  color: #606563;
  width: 90%;
  margin-bottom: 0;
  margin-top: 4px;
  word-break: break-word;
}

.current-jobs>div>div>p {
  font-family: "Poppins";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 24px;
  color: #606563;
  width: 90%;
  margin-bottom: 0;
  margin-top: 4px;
}

.avg-bite {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.avg-bite>strong {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.avg-bite>small {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #8f8f8f;
}

.total-bits {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #606563;
  text-align: left !important;
}

.current-tags>span {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  background: #66d0a951;
  color: var(--sec-green);
  padding: 5px 18px;
  margin: 5px;
  border-radius: 33px;
  display: inline-block;
}

.typesandbudget {
  display: grid;
  grid-template-columns: 50% 40%;
  line-height: 25px;
}

.typesandbudget span {
  text-transform: capitalize;
  font-size: 12px;
}

.current-tags {
  margin: 8px 0;
}

#keywords {
  font-size: 13px;
  color: #5b5b5b !important;
  word-spacing: 35px;
}

.profileConatiner {
  width: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
}

.profileConatiner>hr {
  border-top: 1px solid #f1f1f1;
}

.jobProfileContainer>div>.btn-outline {
  padding: 6px 16px;
  height: auto;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 20px auto;
}

.profile-complete {
  /* background-color: #0cfca415; */
  position: relative;
  padding: 15px;
  box-shadow: 0px 8px 13px rgba(146, 146, 146, 0.1);
  border-radius: 0 0 20px 20px;
}

.progress {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.profile-complete h6 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  /* color: var(--primary-green); */
  display: flex;
  text-align: center;
  margin: 5px 5px;
  justify-content: center;
}

.profile-btn {
  display: flex;
  justify-content: center;
}

.profile-btn>button {
  outline: none;
  border: none;
  background-color: var(--primary-green);
  color: white;
  margin: 20px;
  padding: 10px 30px;
  font-size: inherit;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.profileConatiner>.profile-details {
  border-radius: 20px 20px 0px 0;
  background-color: var(--sec-green);
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-details>img:nth-child(2) {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
  margin-bottom: 20px;
}

.profile-details>.profilename {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.profile-details>.profileDesignation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  color: white;
}

.profile-details>.profileReviews {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #ffffff;
}

.profile-details .rating,
.profile-details .rating p {
  color: white;
}

.profileConatiner>.skillsset-card {
  display: block;
  position: relative;
  top: auto;
  right: auto;
  border: none;
  height: fit-content;
  width: inherit;
}

.profileConatiner>.rate-card {
  border: none;
  margin-top: 0;
  padding-bottom: 0;
  position: relative;
  top: auto;
  right: auto;
  padding: 16px;
  height: fit-content;
  width: auto;
}

.rate-card h2 {
  font-size: 20px;
}

.sideComponent {
  /* margin: 0 30px; */
  border: 1px solid #e1dada;
  border-radius: 15px;
  padding: 5px 11px 18px 17px;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.sideComponent_bid {
  box-shadow: 0px 6px 10px rgba(146, 146, 146, 0.1);
  display: flex;
  justify-content: space-between;
  background-color: #0cfca415;
  border-radius: 20px;
  margin-block: 10px;
}

.sideComponent_link {
  display: flex;
  margin-left: 59%;
  gap: 3px;
  font-size: 14px;
  color: #069b65;
  align-items: center;
}

.sideComponent_bid span {
  font-weight: 800;
  margin: 10px 25px;
}

.bittingCard {
  border: 1px solid #c6c6c6;
  padding: 10px;
  border-radius: 5px;
  margin: 15px 0;
}

@media (max-width: 1300px) {
  #keywords>span {
    font-size: 11px;
  }

  .welcomeConatiner {
    width: 100%;
  }

  .jobContainer {
    width: 100%;
  }

  #keywords {
    margin: 5px 0 0 0;
  }

  .welcome-date {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
  }

  .welcomeName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 60px;
  }

  .jobContainer>span {
    font-size: 18px;
  }

  .jobMatch {
    font-size: 17px;
  }

  .current-jobs>div>.job-name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }

  .current-jobs>div>p {
    margin-top: 0;
    font-size: 12px;
  }

  .avg-bite>strong {
    font-size: 14px;
  }

  .avg-bite>small {
    font-size: 14px;
  }

  .total-bits {
    font-size: 12px;
  }

  .jobMatch>span {
    padding: 4px 10px;
    margin-top: 24px;
    line-height: 25px;
    margin-right: 10px;
  }

  .current-tags>span {
    font-size: 10px;
    padding: 5px 10px;
  }

  .profileConatiner {
    width: 100%;
  }

  .jobProfileContainer>div>.btn-outline {
    font-size: 14px;
  }

  .profile-details>img {
    width: 100px;
    height: 100px;
  }

  .profile-details>.profilename {
    font-size: 18px;
  }

  .profile-details>.profileDesignation {
    font-size: 13px;
  }

  .profile-details>.profileReviews {
    font-size: 13px;
  }

  .profileConatiner>.rate-card {
    border: none;
    margin-top: 0;
    padding-bottom: 0;
    position: relative;
    top: auto;
    right: auto;
    padding-top: 16px;
    height: fit-content;
    width: auto;
  }

  .skillsset-card h5,
  .technicalset-card h5 {
    font-size: 20px;
    margin: 0px;
  }

  .skillsset-card>div>strong,
  .technicalset-card>div>strong {
    font-size: 12px;
    padding: 5px 10px;
  }

  .price-range>img {
    width: 50px;
  }

  .hour-price>.rate {
    font-size: 14px !important;
  }

  .hour-price>span {
    font-size: 16px;
    line-height: 30px;
  }
}

@media (max-width: 1070px) {
  .welcomeConatiner {
    width: 100%;
  }

  .jobContainer {
    width: 100%;
  }

  .jobProfileContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .jobProfileContainer>.compo2 {
    width: 60%;
    align-self: center;
  }

  .jobProfileContainer>.compo {
    width: 80%;
    align-self: center;
    margin-inline: 0;
  }

  .profileConatiner {
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .welcomeName {
    font-size: 18px;
    line-height: normal;
  }

  .welcome-date {
    font-size: 14px;
  }

  .jobContainer {
    height: fit-content;
  }

  .jobContainer>span {
    font-size: 20px;
  }

  .job-container-header>span {
    font-size: 20px;
    line-height: 25px;
  }

  .current-jobs>div>.job-name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
  }

  .current-jobs>div>p {
    font-size: 14px;
  }

  .avg-bite {
    font-size: 10px;
  }

  .jobMatch {
    font-size: 16px;
  }

  /* .jobMatch > span {
    
  } */
  .current-jobs {
    display: flex;
    margin-top: 30px;
    /* justify-content: space-between; */
    flex-direction: column;
  }

  .current-jobs>div>p {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .current-tags>span {
    font-size: 10px;
    padding: 2px 14px;
  }

  .current-tags {
    margin: 0;
  }

  .jobProfileContainer>.compo2 {
    width: 80%;
  }

  .welcomeConatiner {
    height: -moz-fit-content;
    height: fit-content;
    padding: 20px 20px;
    flex-direction: column;
    border-radius: 20px;
    gap: 10px;
  }

  .welcomeName {
    line-height: normal;
    font-size: 16px;
  }

  .welcome-date {
    font-size: 12px;
    line-height: 20px;
  }

  .jobMatch {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .jobMatch>span {
    padding: 4px 10px;
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
  }

  .current-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  /* user profie update */
  .gender label {
    font-size: 10px;
  }
}

/* job detail view */
.see-other-post {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.see-other-post>button {
  padding: 5px 10px;
  margin: 5px;
  font-size: 16px;
  line-height: 30px;
}

.skillview {
  display: flex;
  flex-wrap: wrap;
}

.skillview>span {
  background-color: #434343;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 2px 4px;
  color: white;
  font-size: 11px;
}

/* side bar */
.sidebar {
  background-color: #b0e7bbac;
  color: black;
  width: 13%;
  position: absolute;
  right: 0px;
  z-index: 44;
  margin: 0 15px 0 0;
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* &:before{
		content: '';
		display: block;
		position: absolute;
		width: 10px;
		height: 10px;
		transform-origin: 50% 50%;
		transform: rotate(45deg) translateX(-50%) translateZ(-1px);
		background-color: #b0e7bbb4;
		left: 65%;
		top: 0px;
		z-index: -1;
		box-shadow: -1px -1px 20px #848383aa;} */
}

.sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar li {
  padding-block: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #0f390747;
}

.sidebar li:hover {
  cursor: pointer;
  background-color: #ffffff22;
  color: white;
}

#nohover:hover {
  cursor: default;
  color: #000;
}

.confirmation-popup-box {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #0000005c;
  z-index: 1;
}

.confirmation-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background: white;
  padding: 25px;
  border-radius: 8px;
  text-align: center;
}

.confirmation-popup-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 1300px) {
  .sidebar {
    color: #000;
    position: absolute;
    right: 0;
    width: 13%;
    z-index: 2;
    margin-right: 10px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .sidebar li {
    font-size: 12px;
    padding: 10px;
    text-align: center;
  }
}

@media (max-width: 1060px) {
  .sidebar {
    margin-right: 12%;
  }
}

.navbar_sidebar {
  background-color: #ffffff;
  color: #686868;
  /* width: 13%; */
  position: absolute;
  top: 75px;
  right: 36px;
  z-index: 44;
  /* margin: 0 15px 0 0; */
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.navbar_sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar_sidebar ul span {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1px 13px;
  cursor: pointer;

  &:hover {
    background: #c6c6c63b;
  }
}

.navbar_sidebar ul span li {
  padding-block: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
}

/* .navbar_sidebar span:hover {
  cursor: pointer;
  background-color: #232020db;
  color: white;
} */

@media (max-width: 1300px) {
  .navbar_sidebar {
    color: #000;
    position: absolute;
    right: 0;
    width: 146px;
    z-index: 2;
    margin-right: 10px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  .navbar_sidebar li {
    font-size: 12px;
    padding: 10px;
    text-align: center;
  }
}

@media (max-width: 1060px) {
  .navbar_sidebar {
    margin-right: 12%;
  }
}

/* JobPostForm */
.JobPost {
  margin: 26px auto;
  max-width: 1200px;
}

.JobPostForm {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  padding: 20px;
  padding-inline-start: 2.5rem;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.JobPostForm>div {
  display: flex;
  justify-content: space-between;
}

.JobPostForm>div>div {
  display: flex;
  width: 48%;
  flex-direction: column;
}

.JobPostForm label {
  margin-top: 40px;
  color: #434343;
  font-weight: 600;
  margin-bottom: 6px;
  white-space: nowrap;
}

.JobPostForm input:not([type="submit"]) {
  padding: 10px 10px;
  width: 91%;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
}

.jobpostheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 40px;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  background-color: #f4f8fb;
  padding: 10px 10px 10px 40px;
  color: #0d0d0d;
  border-radius: 20px;
  box-sizing: border-box;
  justify-content: space-between;

  &>div>p {
    font-size: 16px;
    margin-block: 0;
    line-height: normal;
  }
}

.jobpostheading>img {
  height: 100%;
}

input:required:invalid {
  border-color: red;
}

/* publish */
.publish>h3,
.viewSkills>h3,
.publish>div>h3 {
  margin-block-end: 3px;
}

.publishButton {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.publishButton>div {
  margin: 10px;
  font-size: 16px;
  height: 40px;
  padding: 4px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}


.publish-modal {
  display: flex;
  justify-content: space-between;
  padding: 5px;

  & h3 {
    font-size: 16px;
    margin-block-end: 4px;
  }

  & p {
    margin: 0;
  }
}

.viewSkills>div {
  display: flex;
  flex-wrap: wrap;
}

.viewSkills>div>span {
  margin: 5px;
  padding: 5px 10px;
  background: var(--sec-green);
  border-radius: 8px;
}

.tag-input {
  padding: 5px;
  width: 300px;
  min-height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
}

.tag {
  padding: 7px;
}

.tag>button {
  margin-left: 5px;
  padding: 0px 3px;
  border: none;
  font-size: 13px;
  border-radius: 2px;
}

.uploadtags {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.uploadtags>span {
  display: flex;
  border: 1px solid #434343;
  margin: 4px 0px;
  padding: 4px 8px;
  border-radius: 5px;
  box-sizing: border-box;
  width: fit-content;
}

.uploadtags>span>a {
  text-decoration: none;
  color: #434343;
}

.uploadtags>span>button {
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: green;
  align-items: center;
  justify-content: center;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block !important;
  padding: 6px 12px;
  cursor: pointer;
  border: 2px solid #4caf50;
  color: #4caf50 !important;
  font-size: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 10px 0;
}

.custom-file-upload:hover {
  border: 1px solid #4caf4f6b;
}

.uploadDocs {
  margin-top: 10px;
}

@media (max-width: 1300px) {
  .uploadtags>span {
    font-size: 12px;
  }
}

@media (width < 1000px) {
  .jobpostheading {
    padding: 10px 26px;
    border-radius: 13px;
    height: 150px;
  }

  .jobpostheading>div {
    font-size: 20px;
  }
}

/* Bidding && milestone */
.bidInfo {
  display: flex;
}

.bidInfo>p {
  margin-right: 150px;
}

.bid_amount {
  display: flex;
  padding-inline-end: 22px;
}

.bid_range {
  display: flex;
  /* flex-direction: column; */
  width: auto;
  align-items: center;
  gap: 4%;
}

.bid_range:has(.type_job) {
  margin-top: 23px;
  /* width: 18rem; */
  align-items: baseline;
  flex-direction: column;
  gap: 8%;
}

.bid_range>label {
  margin-top: inherit;
}

.bid_Form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.bid_Form>label {
  width: 28%;
  margin-right: 200px;
  box-sizing: border-box;
}

.bid_Form.upper>label {
  margin-right: 0px;
}

.bid_Form input,
.bid_Form textarea {
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  outline: none;
  font-size: 16px;
}

.bid_Form.upper input,
.bid_Form.upper textarea {
  padding: 0px;
  border: none;
  font-family: poppins;
}

.Add_btn {
  outline: none;
  border: none;
  background-color: var(--primary-green);
  color: rgb(255, 255, 255);
  padding: 10px 10px;
  /* font-weight: 600; */
  font-size: 14px;
  /* line-height: 30px; */
  box-sizing: border-box;
  border-radius: 5px;
}

.milestone_Form {
  justify-content: flex-start;
}

.milestone_Form>label {
  width: 45%;
  padding-right: 40px;
  box-sizing: border-box;
}

.milestone_Form.upper>label {
  padding-right: 0px;
}

.bid_Form>.description {
  width: 85%;
}

.add {
  height: 50px;
  /* width:30px; */
}

.remove {
  height: 42px;
}

/* employerSide bidding */
.biddingButton {
  display: flex;
  margin: 0 15px 15px 0;
  font-family: "Poppins";
  flex-direction: column;
}

.biddingButton>button {
  border: 1px solid;
  padding: 10px 30px;
  color: white;
  margin: 5px 0 5px 0;
  cursor: pointer;
  border-radius: 20px;
  font-size: 24px;
}

.bidtags>span {
  padding: 5px 10px;
  margin-top: 10px;
}

.biddingInformation {
  display: flex;
  gap: 20px;
}

.biddingInformation>div {
  display: flex;
  flex-direction: column;
}

.biddingInformation>.bidhead {
  font-size: 15px;
}

.biddingProposal {
  font-size: 14px;
}

.proposal {
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid darkgray;
  padding: 20px;
}

#bidheadValue {
  color: #126412;
  font-weight: 900;
  font-size: 25px;
}

#bidheadDuration {
  font-weight: 900;
  font-size: 25px;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f2f2f2;
}

/* Alternate Row Colors */
table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect */
table tbody tr:hover {
  background-color: #eaf1fb;
  cursor: pointer;
}

.search-box {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 10px;
}

.search-box:focus {
  border-color: #007bff;
  background-color: #fff;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: rgb(0, 0, 0);
  background-color: white;
  font-size: small;
  width: 100%;
  align-self: flex-end;
}

.pagination>button {
  margin: 5px;
  border: none;
  color: rgb(0, 0, 0);
  padding: 4px;
  background-color: white;
}

.pagination input {
  text-align: center;
  color: black;
}

@media (max-width: 1300px) {
  .biddingButton>button {
    font-size: 13px;
  }

  .biddingProposal {
    font-size: 12px;
  }
}

/* / pricing section / */
.Container {
  padding-block: 4%;
  background: #fdfffe;
  position: relative;
}

.header_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_Container>p,
.pricing_feature>p {
  margin: 10px;
  color: gray;
  font-weight: 500;
  z-index: 0;
}

.header_Container>h2 {
  margin: auto;
  font-size: 30px;
  margin-bottom: 35px;
}

#highlight {
  color: var(--primary-green);
}

#highlighted {
  color: #023220ca;
  font-weight: 600;
  margin-inline-end: 5px;
  margin-block-end: 15px;
}

#background_img {
  background-image: url(http://localhost:3000/static/media/winboy.6ad6d06212876e880e6a.png);
  position: absolute;
  width: 77%;
  height: 100%;
  background-repeat: no-repeat;
  filter: grayscale(1);
  /* align-self: center; */
  top: 0;
  right: -11%;
  z-index: 0;
}

.cards_Container {
  display: flex;
  margin-inline: 6%;
  gap: 5%;
  justify-content: center;
}

.pricing_card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30%;
  border: 1px solid #e0dede;
  border-radius: 2px;
  /* / box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 35px -20px; / */
  transition: all 0.3s ease;
  background: white;
}

.pricing_card>button {
  padding: 15px 20px;
  font-size: inherit;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing_feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
}

.pricing_feature>ul {
  width: 100%;
  list-style: none;
  padding-inline-start: 0;
}

.pricing_feature>hr {
  margin: 1rem 0;
  width: 100%;
  height: 1px;
  color: black;
  background-color: #b7b9b9;
  border: 0;
}

.pricing_rate {
  font-size: 30px;
  font-weight: 800;
}

.pricing_feature>ul>li {
  font-size: 14px;
  color: rgb(126, 125, 125);
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  margin-top: 5px;
}

.pricing_upper {
  display: flex;
  align-items: center;
  flex-direction: column;

  &>h2 {
    font-size: 28px;
    margin-block: 10px;
  }

  &>img {
    width: 90px;
    margin: 10px;
  }

  &>span {
    font-size: 28px;
    color: rgb(7, 178, 116);
    margin-bottom: 20px;
  }
}

.pricing_lower {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  opacity: 0.8;
  font-size: 14px;
  text-align: center;

  &>hr {
    opacity: 0.3;
    width: 70%;
  }
}

.pricing_accordion {
  padding-inline: 25%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>h2 {
    font-size: 30px;
    margin-block: 10px;
  }
}

.upper_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upper_header>p {
  margin: 0;
  font-weight: 500;
  text-align: center;
}

/*Accordion  */
.accordion {
  width: 100%;
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: 1px solid #cec9c9;
}

.accordion-header {
  margin-block: 0;
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  font-size: 12px;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button {
  position: relative;
  font-family: poppins;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

/* / Accordian End / */
@media (width < 1000px) {
  .pricing_accordion {
    padding-inline: 4%;

    &>h2 {
      font-size: 20px;
    }
  }

  .accordion-button {
    font-size: 14px;
  }

  .pricing_card {
    padding: 5px;
    margin: 10px;
  }

  .pricing_upper {
    &>h2 {
      font-size: 22px;
    }

    &>img {
      width: 70px;
      margin: 5px;
    }

    &>span {
      font-size: 22px;
    }
  }

  .pricing_lower {
    font-size: 12px;
  }

  .upper_header {
    font-size: 14px;
  }

  .pricing_rate {
    font-size: 22px;
  }

  .jobpostheading>img {
    height: 70%;
  }

  .header_Container {
    &>p {
      font-size: 12px;
      margin-bottom: 0;
    }

    &>h2 {
      font-size: 22px;
    }
  }

  .pricing_card div:nth-child(2)>div {
    min-width: 2px !important;
    width: 90% !important;
    margin-inline: 5%;
  }
}

@media (width < 780px) {
  .upper_header {
    font-size: 12px;
  }

  .pricing_rate {
    font-size: 18px;
  }

  .pricing_upper {
    &>h2 {
      font-size: 18px;
    }

    &>img {
      width: 50px;
    }

    &>span {
      font-size: 18px;
    }
  }

  .jobpostheading>img {
    height: 101px;
  }

  .cards_Container {
    flex-wrap: wrap;
  }
}

@media (width < 580px) {
  .accordion-button {
    font-size: 13px;
  }

  .pricing_accordion {
    padding-inline: 4%;

    &>h2 {
      font-size: 16px;
    }
  }

  .cards_Container {
    flex-direction: column;
    margin: 0;
    gap: 0;
    align-items: center;
  }

  .pricing_card {
    padding-inline: 10px;
    width: 45%;
  }

  .jobpostheading {
    height: fit-content;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* / Toogle in pricing / */
.pricing-toggle-contianer {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  margin-inline: 6%;
}

.toggle-switch-in-pricing {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 40px;
  cursor: pointer;
}

.toggle-switch-in-pricing input[type="checkbox"] {
  display: none;
}

.toggle-switch-in-pricing-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 94%;
  height: 70%;
  transform: translate(-50%, -50%);
  background-color: #ddd;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px #ccc;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-in-pricing-handle {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch-in-pricing::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch-in-pricing input[type="checkbox"]:checked+.toggle-switch-in-pricing-handle {
  transform: translateX(45px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 0 0 3px #05c46b;
}

.toggle-switch-in-pricing input[type="checkbox"]:checked+.toggle-switch-in-pricing-background {
  background-color: #05c46b;
  box-shadow: inset 0 0 0 2px #04b360;
}

.toggle-switch-in-pricing input[type="checkbox"]:checked+.toggle-switch-in-pricing:before {
  content: "On";
  color: #05c46b;
  right: -15px;
}

.toggle-switch-in-pricing input[type="checkbox"]:checked+.toggle-switch-in-pricing-background .toggle-switch-in-pricing-handle {
  transform: translateX(28px);
}

.buton {
  margin-top: 135px;
  background-color: var(--sec-green);
  font-size: 18px;
  font-weight: 700;
  color: white;
  padding: 12px 29px;
  border-radius: 5px;
  border: 2px solid var(--sec-green);
  /* box-shadow: 5px 5px 10px #3ab185; */
  display: inline-block;
  text-decoration: none;
  /* transition: 0.7s; */
  cursor: pointer;
  /* transition: all 0.2s ease; */
}

/* .buton:active{
  transform: translateX(5px) translateY(5px);
  box-shadow: 0px 0px 0px #3ab185;
} */
/* Milestone */
.milestone_Card {
  margin-block-start: 45px;
  padding-block: 20px;
  margin-inline: 20px;
  padding-inline: 40px;
  border: 1px solid darkgrey;
  border-radius: 20px;
}

.milestone_Card:hover {
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  transition: all 0.2s;
}

.mil_head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>h4 {
    font-size: 25px;
    margin-block: 7px;
  }
}

.mil_buton {
  margin-top: inherit;
  background-color: var(--sec-green);
  font-size: 15px;
  font-weight: 700;
  color: white;
  padding: 12px 29px;
  border-radius: 5px;
  border: 2px solid hsl(158, 92%, 36%);
  box-shadow: 0px 0px 0px 0px #04a066;
  transition: 0.2s;
  cursor: pointer;
}

.mil_buton:hover {
  box-shadow: inset 0px 0px 10px 0px #0d0d0d33;
  border-radius: 0;
}

.mil_buton.disabled:hover {
  box-shadow: inset 0px 0px 10px 0px #191a1936;
  cursor: default;
}

.mil_buton.disabled {
  background-color: rgba(128, 128, 128, 0.6);
  border: 2px solid rgba(128, 128, 128, 0.6);
}

.mil_detail {
  margin-block: 12px;
}

.mil_detail>h5 {
  font-size: 15px;
  margin-block: 8px;
  margin-inline-end: 4em;
}

.mil_detail>span {
  font-size: 15px;
  word-spacing: 2px;
  color: darkslategray;
}

.mil_desc {
  &>div {
    display: flex;
    gap: 33px;
    /* justify-content: space-around; */
  }
}

.iconTool {
  position: relative;
  cursor: pointer;

  &:hover .tooltip {
    display: block;
  }
}

.tooltip {
  padding: 10px;
  position: absolute;
  width: auto;
  white-space: nowrap;
  word-wrap: no-wrap;
  box-shadow: 1px 1px 10px hsla(159, 47%, 73%, 0.476);
  border-radius: 5px;
  background-color: #fff;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  transform-style: preserve-3d;
  z-index: 200;
  font-size: 0.9em;
  display: none;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    left: 50%;
    top: -1px;
    z-index: 400;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%) translateZ(-1px);
    background-color: #fff;
    left: 50%;
    top: 0px;
    z-index: -1;
    box-shadow: -1px -1px 20px #aaa;
  }
}

.tooltip_dang {
  color: hsl(158, 92%, 36%);
  box-shadow: 1px 1px 20px rgba(hsl(158, 92%, 36%), 0.3);
}

@media (width < 900px) {
  .buton {
    font-size: 12px;
    padding: 10px 14px;
  }

  .mil_head {
    &>h4 {
      font-size: 18px;
    }
  }

  .mil_buton {
    font-size: 12px;
    padding: 8px 12px;
  }

  .mil_desc {
    &>div {
      flex-wrap: wrap;
      gap: 0;
    }

    .mil_detail>h5 {
      font-size: 13px;
      margin-block-end: 4px;
    }

    .mil_detail>span {
      font-size: 13px;
      word-spacing: 1px;
      color: darkslategray;
    }
  }

  .milestone_Card {
    padding-inline: 20px;
  }
}

/* For Chat overlay */
.chat-overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  /* pointer-events: none; */
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.msg-overlay-container {
  height: 0;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
  flex: 1;
}

.msg-overlay {
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  /* height: calc(100vh - 350px); */
  height: calc(100vh - 200px);
  flex: 0 0 350px;
  width: 288px;
  min-width: 0;
  background: white;
  border-radius: 5px 5px 0 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transform: translateY(0);
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
}

.msg-overlay.minimised {
  transform: translateY(100%) translateY(-40px);
}

.msg-overlay-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 14px;
  background: whitesmoke;
  font-weight: 600;
  color: #5b5b5b;
  border-radius: inherit;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) -1px -3px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &>img {
    width: 35px;
    height: 35px;
    margin-inline: 0.7rem;
    border-radius: 50%;
  }
}

.msg-overlay-header:hover {
  background: rgba(221, 220, 220, 0.493);
}

.msg-overlay-profiles {
  &>hr {
    border: 1px solid #5b5b5b1f;
  }
}

.overlay-profiles {
  display: flex;
  width: 100%;
  padding-block: 0.9rem;
  border-radius: 5px;
}

.overlay-profiles img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin-inline: 0.6rem;
  margin-block: auto;
}

.overlay-profiles-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  width: 74%;
  padding-left: 0.2rem;
}

.overlay-profiles:hover {
  background: rgba(214, 211, 211, 0.426);
  cursor: pointer;
}

.overlay-profiles-details h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.overlay-profiles-details p {
  margin: 0;
  font-size: 12px;
  color: rgba(96, 101, 99, 1);
  font-weight: 400;
}

.unread-messages-indicator {
  background-color: #07b273b0;
  color: white;
  border-radius: 50%;
  padding: 1px 7px;
  margin-inline: auto 10px;
  /* outline: 1px solid lightgrey; */
  font-size: 12px;
  /* position: relative; */
  top: 50%;
  /* right: 10px; */
  /* transform: translate(0, -50%); */
}

/* Chat Section */

/* / Chat container / */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: calc(100vh - 422px); */
  height: calc(100vh - 250px);
  justify-content: flex-end;
}

/* / Chat window / */
.chat-window {
  width: 100%;
  /* min-height: 100px; */
  /* max-height: 400px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 10px; */
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

/* / Chat messages / */
.chat-messages {
  padding: 10px;
  display: flex;
  /* height: calc(68vh - 151px); */
  flex-direction: column;

  & span {
    font-weight: 500;
    display: flex;
    background: rgba(151, 173, 198, 0.0588235294);
    width: max-content;
    padding: 2px 8px;
    outline: 1px solid rgba(91, 91, 91, 0.3568627451);
    border-radius: 5px;
    margin: 8px auto;
    color: #5b5b5b;
    font-size: 12px;
    justify-content: center;
  }
}

.chat-message-leftExtra span {
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
}

.chat-message-RightExtra span {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  background: transparent;
  outline: none;
  padding: 0;
  margin: 0;
}

.chat-message-LeftExtra span a,
.chat-message-leftExtra span p {
  background-color: #e2dfdf;
  padding: 5px 10px;
  border-radius: 15px;
}

.chat-message-RightExtra span a,
.chat-message-RightExtra span p {
  background-color: #b9f4df;
  padding: 5px 10px;
  border-radius: 15px;
}

.chat-message-RightExtra span a,
.chat-message-LeftExtra span a {
  background-color: white;
}

/* / Chat bubble / */
.chat-bubble {
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
  max-width: 80%;
  word-wrap: break-word;
  border-radius: 10px;
}

/* / Right-aligned bubble for sender's messages / */
.chat-bubble.right {
  align-self: flex-end;
  /* background-color: hsl(
    158deg 84.5% 59.69% / 34%
  );  */
  border-radius: 10px 10px 10px 10px;
}

/* Left-aligned bubble for receiver's messages */
.chat-bubble.left {
  align-self: flex-start;
  /* background-color: #e8eaed; */
  border-radius: 10px 10px 10px 10px;
}

.chat-input {
  position: sticky;
  z-index: 1000;
  bottom: 0px;
  border-radius: 20px;
  background: white;
}

.no-chat-users-placeholder {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 20px;
  justify-content: center;

  &>p {
    text-align: center;
    font-size: 18px;
    color: #5b5b5b;
    font-weight: 600;
  }
}

/* / Input container / */
.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.input-container>input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

/* / Send button / */
.input-container>button {
  padding: 8px 15px;
  border: none;
  background-color: #4caf50;
  /* You can customize this color */
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* / Login form / */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.file-upload-container {
  /* margin-top: 10px; */
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 13px;

  align-items: center;

  & button {
    border: none;
    background-color: #07b273;
    padding: 5px 20px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: white;
    border-radius: 15px;

    &:hover {
      background-color: #037b4f;
    }
  }
}

/* / File input / */
.file-upload-container input[type="file"] {
  display: none;
  /* Hide the default file input */
}

/* / Custom file input style / */
.file-upload-container label {
  display: flex;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #5b5b5b20;
  }

  &>svg {
    width: 25px;
    height: 25px;
  }
}

/* / CSS for file icon / */
.file-icon {
  margin-right: 5px;
}

/* / CSS for file name / */
.file-name {
  font-weight: bold;
  color: #333;
}

/* / CSS for image thumbnail / */
.image-thumbnail {
  max-width: 100%;
  max-height: 150px;
  border-radius: 5px;
  margin: 5px;
}

/* / Additional styles for chat bubbles / */
.chat-bubble {
  position: relative;
}

/* 
.chat-bubble.left:before {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  border-width: 7px;
  border-style: solid;
  border-color: transparent #e8eaed transparent transparent;
  transform: translateY(20%);
}
.chat-bubble.right:before {
  content: "";
  position: absolute;
  right: -13px;
  top: 0;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent
    hsl(158deg 84.5% 59.69% / 34%);
  transform: translateY(20%);
} */

/*/ Reveiw  /*/
.Review-container {
  display: flex;
  flex-direction: column;
  background: #ebfff8;
  align-items: center;
  border-radius: 20px;
  margin-inline: 19%;
  margin-block: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 6px 6px 0px inset;
}

@media (max-width: 1300px) {
  .Review-container {
    margin-inline: 0;
  }
}

.reviews-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.reviews-form>span {
  font-weight: 800;
  margin: 10px;
}

.reviews-form>textarea {
  border-radius: 10px;
  padding: 10px;
}

.reviews-form .buton {
  margin-top: 0;
  border-radius: 10px;
  font-size: 16px;
}

.star-rating>button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.star-rating-star {
  font-size: 60px;
}

.on {
  color: var(--sec-green);
}

.off {
  color: rgba(224, 223, 255, 0.723);
}

/* / Featred Freelancers / */

.feat-freelancers-container {
  background: #f6f6f6;
  display: flex;
  margin-top: 4rem;
  flex-direction: column;

  padding-block: 40px;

  &>h3 {
    margin-bottom: 20px;
    margin-top: 40px;
    margin-inline: auto;
  }
}

.featCards {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  max-width: 1300px;
  margin: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* &::-webkit-scrollbar-track {
    background: rgba(128, 128, 128, 0);
  }

  &::-webkit-scrollbar-thumb {
    background: #07b2732a;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--sec-green);
  }  */
}

.featCards>h3 {
  font-size: 40px;
  margin: 20px;
  font-weight: 600;
  text-align: center;
  color: #355449;
}

.featCards .location-content,
.featCards .line {
  display: none;
}

.featCards .freelancers-card .rate {
  font-size: 16px;
}

.featCards .freelancers-card {
  max-width: 237px;
  min-width: 200px;
  padding: 8px;
}

.featCards .freelancers-card>div,
.featCards .freelancers-card>span {
  margin: 3px;
  /* line-height:3px; */
}

.featCards .freelancers-card>.view-profile {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid #f6f6f6;
}

.featCards .freelancers-card>img {
  width: 80px;
  height: 80px;
  margin-top: 5px;
}

.featCards .name {
  font-size: 18px;
}

.featCards .skills>button {
  padding: 3px 13px;
  font-size: 12px;
}

.featCards .view-profile>img {
  width: 30px;
}

@media (width < 1000px) {
  .featCards h3 {
    font-size: 20px;
  }
}

/* /  Skill Test  / */
.skillTest-container {
  padding: 7%;
  display: flex;
  flex-direction: row-reverse;
  border-radius: 5px;
  background: rgba(128, 128, 128, 0.055);
}

.skillTest-sidePanel {
  background: white;
  width: 30%;
  margin-left: 5%;
  padding: 1rem 2rem;
  border-radius: 10px;
  height: max-content;
  outline: 1px solid rgb(0 0 0 / 16%);
  outline-offset: 10px;
}

.skillTest-mainPanel {
  background: white;
  width: 70%;
  padding: 1rem 2rem;
  /* margin-right: 6%; */
  border-radius: 10px;
  outline-offset: 10px;
  outline: 1px solid rgb(0 0 0 / 16%);
}

.mainPanel-header {
  margin-bottom: 1.7em;
}

.mainPanel-header>h3,
.sidePanel-header>h3 {
  margin-block: 0.5em;
  font-size: 20px;
}

.mainPanel-header>span,
.sidePanel-header>span {
  font-size: 16px;
  color: #3b3b3bd5;
  /* font-weight: 500; */
}

.sidePanel-stats {
  display: flex;
  margin-top: 1em;
}

.sidePanel-stats div {
  text-align: center;
}

.mainPanel {
  /* display: grid;
  grid-template-columns: repeat(2,1fr); */
  display: flex;
  flex-direction: column;
}

.mainPanel-cards {
  border: 1px solid #5555;
  margin-block: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  padding: 8px 15px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 5px;
  }
}

.mainPanel-image>img {
  width: 60px;
  padding-top: 10px;
}

.mainPanel-detail {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  padding: 10px 0 0 10px;
  column-gap: 15px;
}

.mainPanel-detail>div {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  /* flex-direction: column; */
  margin-top: 5px;
  gap: 5px;
}

.mainPanel-detail>h4 {
  margin: 0;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.mainPanel-detail p {
  color: #575555;
  margin: 0;
  /* font-weight: 500; */
}

#topic {
  font-weight: 600;
}

#num {
  text-align: center;
  color: var(--primary-green);
  font-size: 35px;
  margin: 5px;
}

@media (width < 1000px) {
  .featCards .name {
    font-size: 14px;
  }

  .rating p {
    font-size: 12px;
  }

  .featCards .freelancers-card .rate {
    font-size: 14px;
  }

  .view-profile {
    font-size: 14px;
  }

  .featCards .view-profile>img {
    width: 22px;
  }

  .featCards .freelancers-card>img {
    width: 67px;
    height: 67px;
  }
}

@media (width < 840px) {
  .skillTest-container {
    flex-direction: column;
  }

  .skillTest-sidePanel {
    width: auto;
    margin: 5%;
  }

  .feat-freelancers-container {
    font-size: 20px;
  }
}

.instructions h4 {
  font-size: 18px;
  margin-block: 20px;
}

.instructions li {
  font-size: 14px;
  font-weight: 500;
  color: dimgrey;
}

.instructions span {
  color: black;
  font-weight: 600;
}

.instructions .buton {
  display: flex;
  margin-left: 80%;
  margin-top: 0;
  font-weight: 500;
  padding: 10px 29px;
  gap: 1rem;
}

/* /Payments/ */
.payment-container {
  padding-block: 20px;
  margin-top: 0;
  border-radius: 5px;
}

.payment-jobs-amount {
  border-radius: 10px;
  background: aliceblue;
  padding: 10px 30px;
  align-self: center;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 9px 2px inset;
}

.payment-jobs-amount span {
  font-weight: 500;
  color: #606563;
  display: flex;
  font-size: 14px;
  align-items: baseline;
}

.payment-jobs-amount>span>p {
  margin: 0;
  font-weight: 600;
  color: black;
}

.payment-container-header h2 {
  text-align: center;
  color: var(--primary-green);
  margin-bottom: -10px;
  margin-top: 40px;
  font-size: 35px;
}

.underline {
  position: relative;
  left: -10%;
  bottom: -7px;
  height: 4px;
  width: 120%;
  background: var(--sec-green);
}

@media (width<768px) {
  .payment-jobs-amount {
    padding: 5px 10px;

    &>span {
      font-size: 12px;
    }
  }
}

/*/ Question-Answer Section==========================/ */
.QA-container {
  padding: 6% 10%;
  background: #fdfdfd;
}

.QA-container>div {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px 0px;
  border-radius: 20px;
}

.Question-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px 30px;
  border-radius: 20px 20px 0 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.About__Word__Counting {
  position: absolute;
  font-size: 12px;
  right: 0;
  bottom: 0;
}

.Answer-section {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0 0 20px 20px;
  padding: 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Answer-section-footer {
  display: flex;
  justify-content: space-between;
  margin: 10px 15px;
}

.Answer-section-footer .mil_buton {
  margin-top: 0;
  width: fit-content;
  height: 35px;
  margin-bottom: 10px;
  padding: 7px 20px;
  border-radius: 15px;
}

.Answer-section-footer .btn-outline {
  width: fit-content;
  height: 35px;
  margin-bottom: 10px;
  padding: 7px 20px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 600;
}

.Answer-section .label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 14px 16px;
  margin: 5px 0;
  cursor: pointer;
  transition: 0.3s;
}

.Answer-section .label:hover,
.Answer-section .label:focus-within,
.Answer-section .label:active {
  background: hsla(0, 0%, 80%, 0.14);
}

.Answer-section .radio-input {
  position: absolute;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.Answer-section .radio-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background: var(--sec-green);
  position: relative;
}

.Answer-section .radio-design::before {
  content: "";
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: 0.3s;
}

.Answer-section .radio-input:checked+.radio-design::before {
  transform: scale(0);
}

.Answer-section .label-text {
  color: hsl(0, 0%, 60%);
  margin-left: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  transition: 0.3s;
}

.Answer-section .radio-input:checked~.label-text {
  color: hsl(0, 0%, 40%);
}

.result-section-top {
  border-radius: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.result-section-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.result-section-mid h3 {
  font-size: 30px;
  margin: 10px;
}

.result-section-bot {
  padding: 20px;
  display: flex;
  justify-content: center;
  background: white;
  color: black;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px -2px;

  & h4 {
    font-size: 20px;
    margin: 10px;
  }

  & p {
    font-size: 20px;
  }
}

.piechart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  position: relative;
}

.chart_inside {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
  margin: 15px;
  top: 0;
  right: 0;
  background-color: #1e262f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tooltipQA {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  background-color: #333b44;
  border-radius: 10px;
  padding: 10px 20px;
}

.tooltip_div {
  width: 100%;
  border-radius: 10px;
  margin-block: 5px;
  padding-inline: 10px;
  background-color: #1b222a;
}

.tooltipQA input {
  border: none;
  color: #ffffff;
  padding: 5px 10px;
  background-color: transparent;
}

.ui.inverted.progress {
  background: rgba(255, 255, 255, 0.08);
  border: none;
}

.progress-bar-skilltest {
  background-color: var(--primary-green);
  height: 10px;
  border-radius: 10px;
}

.ui.progress {
  position: relative;
  display: block;
  max-width: 100%;
  border: none;
  margin: 1em 0;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 0.28571429rem;
  transition: width 0.1s ease, background-color 0.1s ease;
}

.ui.progress .bar {
  height: 1.75em;
}

.ui.green.inverted.progress .bar {
  background-color: var(--primary-green);
  border-radius: 5px;
}

.ui.red.inverted.progress .bar {
  background-color: #f55c5c;
  border-radius: 5px;
}

.ui.black.inverted.progress .bar {
  background-color: grey;
  border-radius: 5px;
}

.ui.inverted.progress .bar>.progress {
  color: #f9fafb;
}

.ui.progress .bar>.progress {
  white-space: nowrap;
  position: absolute;
  width: auto;
  font-size: 0.92857143em;
  top: 50%;
  right: 0.5em;
  left: auto;
  bottom: auto;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: none;
  margin-top: -0.5em;
  font-weight: 700;
  text-align: left;
}

@media (width < 900px) {
  .result-section-top {
    margin-top: 30px;
  }
}

/* / ==================================Invoice ===============================================/ */
.invoice-card {
  padding: 50px;
  border: none;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
}

.invoice-heading {
  display: flex;
  justify-content: center;
}

.invoice-heading>h2 {
  font-size: 59px;
  font-style: normal;
  font-weight: 600;
  color: #606563;
  margin: 15px 0 15px;
  letter-spacing: 2px;
}

.addr-heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #7c8c2b;
}

.addr {
  font-size: 16px;
  font-style: normal;
}

.download-button {
  font-size: 20px;
  margin-right: 20px;
  color: rgba(7, 178, 116, 1);
}

.download-button:hover {
  font-size: 20px;
  margin-right: 20px;
  color: rgb(146, 145, 145);
  cursor: pointer;
}

.table-invoice>tbody>tr>td {
  border: 0.2px solid #d7b703e5;
  padding: 5px;
  height: 40px !important;
  vertical-align: middle;
  text-align: center;
}

.table-invoice>thead>th {
  border: 0.2px solid #d7b703e5;
  padding: 5px;
  color: #7c8c2b;
  height: 40px !important;
  font-weight: 600;
  vertical-align: middle;
  text-align: center !important;
}

.table-invoice {
  font-size: 14px;
  padding: 10px;
}

/* //--------------------------------------Review Slider-------------------------// */
.Review_Slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 60px 0;
}

.Review_Slider_Box {
  width: 60%;
  overflow: hidden;
}

.Review_Slider_Box div img {
  width: 130px;
  height: 130px;
  filter: grayscale(1);
  opacity: 1;
}

.slider_box_title {
  line-height: 40px;
  margin-inline: auto;

  &>p {
    margin: 0px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
  }

  &>h2 {
    margin: 0px;
    text-transform: uppercase;
    font-size: 32px;
  }

  &>h3 {
    margin: 0px;
    text-transform: uppercase;
    color: rgb(13, 205, 134);
  }
}

.Swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function,
      initial);
  box-sizing: content-box;
  text-align: center;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-pagination {
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 3px;
  display: inline-flex;
  background: black;
  opacity: 0.2;
  margin: 5px;
  cursor: pointer;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: rgba(7, 178, 116, 1);
  opacity: 1;
}

.star-2 svg {
  color: #e8e800;
  font-size: 21px;
  margin-top: 10px;
}

/* .swiper-slide>img {
    height: auto;
    width: 20vw;
} */
@media (width < 768px) {
  .Review_Slider_Box {
    width: 90%;
  }

  .Review_Slider_Box div img:nth-child(1) {
    position: absolute;
  }

  .Review_Slider_Box div img:nth-child(3) {
    display: none;
  }

  .slider_box_title {
    line-height: 30px;

    &>p {
      font-size: 16px;
    }

    &>h2 {
      font-size: 22px;
    }

    &>h3 {
      font-size: 22px;
    }
  }
}

/* /-------------------testimonial rating----------------- */
[data-star] {
  text-align: left;
  font-style: normal;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}

[data-star]::before {
  display: block;
  content: "★★★★★";
  color: #eee;
}

[data-star]::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  width: 0;
  color: #ff8c00;
  overflow: hidden;
  height: 100%;
}

[data-star^="0.1"]::after {
  width: 2%;
}

[data-star^="0.2"]::after {
  width: 4%;
}

[data-star^="0.3"]::after {
  width: 6%;
}

[data-star^="0.4"]::after {
  width: 8%;
}

[data-star^="0.5"]::after {
  width: 10%;
}

[data-star^="0.6"]::after {
  width: 12%;
}

[data-star^="0.7"]::after {
  width: 14%;
}

[data-star^="0.8"]::after {
  width: 16%;
}

[data-star^="0.9"]::after {
  width: 18%;
}

[data-star^="1"]::after {
  width: 20%;
}

[data-star^="1.1"]::after {
  width: 22%;
}

[data-star^="1.2"]::after {
  width: 24%;
}

[data-star^="1.3"]::after {
  width: 26%;
}

[data-star^="1.4"]::after {
  width: 28%;
}

[data-star^="1.5"]::after {
  width: 30%;
}

[data-star^="1.6"]::after {
  width: 32%;
}

[data-star^="1.7"]::after {
  width: 34%;
}

[data-star^="1.8"]::after {
  width: 36%;
}

[data-star^="1.9"]::after {
  width: 38%;
}

[data-star^="2"]::after {
  width: 40%;
}

[data-star^="2.1"]::after {
  width: 42%;
}

[data-star^="2.2"]::after {
  width: 44%;
}

[data-star^="2.3"]::after {
  width: 46%;
}

[data-star^="2.4"]::after {
  width: 48%;
}

[data-star^="2.5"]::after {
  width: 50%;
}

[data-star^="2.6"]::after {
  width: 52%;
}

[data-star^="2.7"]::after {
  width: 54%;
}

[data-star^="2.8"]::after {
  width: 56%;
}

[data-star^="2.9"]::after {
  width: 58%;
}

[data-star^="3"]::after {
  width: 60%;
}

[data-star^="3.1"]::after {
  width: 62%;
}

[data-star^="3.2"]::after {
  width: 64%;
}

[data-star^="3.3"]::after {
  width: 66%;
}

[data-star^="3.4"]::after {
  width: 68%;
}

[data-star^="3.5"]::after {
  width: 70%;
}

[data-star^="3.6"]::after {
  width: 72%;
}

[data-star^="3.7"]::after {
  width: 74%;
}

[data-star^="3.8"]::after {
  width: 76%;
}

[data-star^="3.9"]::after {
  width: 78%;
}

[data-star^="4"]::after {
  width: 80%;
}

[data-star^="4.1"]::after {
  width: 82%;
}

[data-star^="4.2"]::after {
  width: 84%;
}

[data-star^="4.3"]::after {
  width: 86%;
}

[data-star^="4.4"]::after {
  width: 88%;
}

[data-star^="4.5"]::after {
  width: 90%;
}

[data-star^="4.6"]::after {
  width: 92%;
}

[data-star^="4.7"]::after {
  width: 94%;
}

[data-star^="4.8"]::after {
  width: 96%;
}

[data-star^="4.9"]::after {
  width: 98%;
}

[data-star^="5"]::after {
  width: 100%;
}

.swiper-slide .star {
  font-size: 24px;
  margin-bottom: 0px;
}

/* ---------------------Sitemap---------------------------- */
.Sitemap-container {
  padding-inline: 7%;

  &>h2 {
    padding-block: 7vh;
    font-size: 28px;
    padding-inline: 3%;
    margin: 0;
  }
}

.sitemap-lower {
  display: grid;
  grid-template-columns: 1fr minmax(0, 3fr);
  margin-inline: 3%;
  margin-bottom: 15vh;
  font-size: 16px;
}

.sitemap-link {
  display: grid;
  grid: auto/repeat(3, 1fr);
  font-size: 14px;
  gap: 10px;
  font-weight: 500;

  /* color: rgba(7, 178, 116, 1); */
  &>a {
    color: #3b1ae8;
  }
}

.sitemap-link-head {
  font-variant-caps: petite-caps;
  font-weight: 600;
  font-size: 18px;
}

/* ----------------------------- Digilanxer Guide ----------------------- */
.Digilanxer-guide-container {
  display: flex;
  gap: 20px;
}

.Digilanxer-guide-main {
  width: 70%;
}

.Digilanxer-guide-sidepanel {
  width: 24%;
  position: sticky;
  background: #6065630f;
  padding: 21px;
  margin-top: 21px;
  top: 20px;
  border-radius: 20px;
  height: fit-content;

  &>span {
    font-size: 16px;
    display: flex;
    padding-block: 10px;
    gap: 5px;
    font-weight: 500;
    align-items: baseline;

    &:hover {
      cursor: pointer;
      color: #07b273;
    }

    &>p {
      margin: 0;
    }
  }
}

/* ==================== Contact Form ===========================*/

.contact-container {
  display: flex;

  &>hr {
    border: 1px solid #80808017;
    margin-block: 20px;
  }
}

.contact-mainpanel {
  width: 65%;
}

.contact-form {
  padding: 0;
  margin: 0;
  box-shadow: none;
}

.contact-form-upper {
  display: flex;
  flex-direction: column;

  &>hr {
    border: 1px solid #80808017;
    width: 100%;
    margin-block: 40px 0;
  }
}

.contact-sidepanel {
  margin-top: 50px;
  width: 300px;
}

.JobPostForm .contact-form-detail {
  width: 100%;
}

@media (width > 1200px) {
  .JobPostForm .contact-form-detail {
    width: 60%;
  }

  .contact {
    padding-inline: 10vw;
    margin-top: 4%;
  }
}

@media (width <900px) {
  .contact-mainpanel {
    width: 100%;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-sidepanel {
    margin-top: 50px;
    width: 100%;
  }
}

/* ========================== Notifications ================================== */
.notification {
  position: absolute;
  top: 75px;
  z-index: 44;
  right: 10%;
  width: 370px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.99);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &>ul {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 75vh;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  &>ul>hr {
    margin: 0;
    border: 1px solid rgba(128, 128, 128, 0.167);
  }

  & li {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 5px 8px;
    height: 55px;

    &>img {
      width: 50px;
      clip-path: circle(50% at 50% 50%);
    }

    &>span {
      padding-left: 20px;
    }

    &:hover {
      cursor: pointer;
      background-color: #63636322;
    }
  }
}

.notification-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 3px 3px 0 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding-block: 3px;

  & h5,
  p {
    font-size: 14px;
    margin: 5px 8px;
  }
}

.notification-base {
  & h5 {
    font-size: 14px;
    margin: 0;
    padding: 5px;
    border-radius: 0 0 3px 3px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
}

.notification-message {
  position: relative;

  &:hover {
    background: none;
  }

  &>div {
    padding: 10px;
  }

  & p {
    margin: 0;
    font-weight: 500;
    color: #565555;
  }
}

.notification-message.notread {
  &:hover {
    background: #07b27323 !important;
  }
}

.notification-message:not(.read):before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--primary-green);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
}

.notification-badge {
  position: relative;
  top: -5px;
  right: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 1px;
  height: 14px;
  width: 12px;
  line-height: 12px;
  text-align: center;
  font-size: 11px;
}

.no-notification-placeholder {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 20px;
  justify-content: center;

  &>p {
    text-align: center;
    font-size: 18px;
    color: #5b5b5b;
    font-weight: 600;
  }
}

@media (max-width: 400px) {
  .notification-badge {
    display: none;
  }
}

/*============================= user-reviews ===========================*/
.user-review-container {
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 50% 50%;
}

.user-review-box {
  outline: 1px solid lightgrey;
  padding: 20px;
  margin: 20px;
  border-radius: 3px;

  &>.star {
    display: flex;
    gap: 1.8rem;
    font-size: 12px;
    margin-top: 0;

    &>i {
      font-size: 22px;
    }
  }

  &>div>span {
    color: #5b5b5b;
    font-weight: 600;
  }

  &>div>p {
    font-weight: 500;
    color: #5b5b5b;
  }
}

/* =============================footer-terms============================= */

.footer-terms {
  display: flex;
  padding: 15px;
  margin-block: 20px;
  flex-direction: column;
  font-size: 14px;
}

.footer-terms-heading {
  color: #5b5b5b;
  font-size: 16px;
  font-weight: 500;
  margin-block: 10px;
}

.privacy-footer {
  max-width: 1100px;
}


/* Specific styling for the testimonial form */
.testimonial-form {
  margin: 20px 4%;
  padding: 20px;
  border: 1px solid #a3a3a3;
  border-radius: 10px;
}

/* Style for form groups */
.testoimonial-form_group {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
}

/* Style for labels */
.testimonial-form-lebel {
  display: block;
  margin-bottom: 5px;
}

.testimonial-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .testimonial-form-add{
  padding: 10px;
  width: 80px;
  background-color: transparent;
  border: 1px solid rgba(7, 178, 116, 1);
  color: #000;
  border: none;
  border-radius: 10px;
} */
/* Style for inputs and textarea */
.testimonial-form-input,
.testimonial-form-textarea {
  width: -webkit-fill-available;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}


/* .pagination{
  
} */
.pagination_controls_component {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pagination_controls_component button {
  background: transparent;
  border: none;
  padding: 5px 10px;
  border-radius: 50%;
}

.pagination_controls_component li .active {
  background-color: #cef0e4;
}


.Online_dot {
  width: 8px;
  height: 8px;
  background-color: #31de00;
  border-radius: 50%;
}

.asterisk {
  color: #ba0000;
}

.react-tel-input .form-control {
  width: 100%;
  background-color: blue;
}

.invite {
  background-color: #07b273;
  font-size: 18px;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.invite_model,
.TimeSheetModel {
  position: fixed;
  z-index: 1;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.invite_model_content,
.TimeSheetModelContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-height: 90%;
  width: 50%;
  overflow: auto;
  border-radius: 10px;
}

.invite_close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
  padding: 0px;
}


.invite_close:hover,
.invite_close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.react-tel-input .form-control {
  width: 100% !important;
}


.paymentMode select {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
}


.disputed__container {
  /* height: 300px; */
  margin-top: 10px;
  border-radius: 13px;
  border: 1px solid lightgrey;
}

.paymentMode select option {
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}


.disputee__para {

  margin-left: 1rem;
  color: gray;
  font-size: 14px;
}

.disputee2para2 {

  margin: 0;
  margin-top: 0.9rem;
  margin-left: 1rem;
  color: gray;
  font-family: 'Poppins';
  font-weight: 900;
  color: #001;

}


.Product_Info input,
textarea {
  padding: 10px 0 10px 10px;
  border: 1px solid hsl(0, 0%, 84%);
  color: #767676;
  background: #ffffff;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.Product_Info div {
  margin-top: 5%;
}

.Product_Info button,
.Product_Info_Interest {
  padding: 10px 0 10px 10px;
  color: #fff;
  font-weight: bold;
  background: #05c46b;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

.Product_Info button:hover,
.Product_Info_Interest:hover {
  color: #767676;
  border: 1px solid hsl(0, 0%, 84%);
  background: #ffffff;
}

.work-description {
  word-break: break-word;
  overflow-wrap: anywhere;
}


.suggestions-list {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  width: 70%;
  box-sizing: border-box;
  margin-right: 10px;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}


/* src/components/Certificate.css */
.certificate {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid #000;
  text-align: center;
  font-family: 'Arial, sans-serif';
}

.certificate h1 {
  font-size: 2em;
}

.certificate p, .certificate h2, .certificate h3, .certificate h4 {
  margin: 10px 0;
}

@media (max-width: 600px) {
  .certificate {
    padding: 10px;
  }

  .certificate h1 {
    font-size: 1.5em;
  }
}



.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}


.dropdown:hover .dropdown-content {
  display: block;
}

.facebook-login-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0px;
  font-size: 16px;
  cursor: pointer;
}
