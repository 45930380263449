/* CardComponentWithStyles.css */

/* .profile-card {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 60px;
    box-sizing: border-box;
} */

.inner-card {
    width: 20%;
    height: 250px;
    padding: 40px;
    /* background-color: rgb(243, 136, 136); */
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.img-style {
    width: 100%;
    height: 90px;
    object-fit: cover;
    box-sizing: border-box;
    border-radius: 5px;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
    box-sizing: border-box;
}

.btn-style {
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
}

.card-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;  
}

/* Media query styles */
@media (min-width: 1240px) {
    .profile-card {
        width: 25%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1220px) {
    .profile-card {
        width: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .card-container {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
    }
}